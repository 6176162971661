.sidebar_con {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: grid;
  overflow: hidden;
  grid-template-columns: 250px 1fr;
  transition: grid-template-columns 0.4s ease;

  .left_con {
    overflow: hidden;
    height: 100vh;
    border-radius: 0px 25px 20px 0px;
    background: white;

    .logo_main_con {
      margin: 10px 0px 22px 25px;
    }

    .navlink_con {
      padding: 0px 20px;
      height: 83%;
      overflow: auto;

  

      .navlink {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: black;
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize;
        margin-top: 12px;
        font-family: "Lufga";
        padding:0.4rem;

      }

      .superadmin_dashboard_sub_navlink .navlink{
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: black;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        font-family: "Lufga";
        margin-top:unset;
        text-align: start;
      }

      .superadmin_dashboard_sub_navlink {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: black;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        font-family: "Lufga";
        padding:0.2rem;
        margin-top: 10px;
        background: #CAECF1;
        border-radius: 10px;
      }
      

      
    }
  }

  .right_con {
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    .top_bar_con {
      height: 75px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 28px;

      .nav_title {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }


      .searchbar {
        border-radius: 18px;
        background-color: rgb(243, 241, 241);
        width: 250px;
        padding:0 10px 0 20px;
        border: 1px solid #E7EAE9;
        height: 32px;
        background: transparent;
      }

      .searchbar::placeholder {
        color: #85878D;
        font-family: Avenir Next LT Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }


      .language_select {
        border-radius: 12px;
        background: rgba(15, 155, 90, 0.15);
        margin-right: 20px;
      }

      .icon_con {
        display: flex;
        align-items: center;
        font-size: 30px;
        column-gap: 20px;
      }
    }

    .children_con {
      padding: 0px 24px 24px 24px;
      background-color: #F5F6FA;
      height: calc(100vh - 75px);
      overflow: auto;
    }
  }
}

.profile_container{
  border-radius: 50%;
  background: #d8d8d8;
  padding:5px
}

.profile_container img{
  height: 80%;
  width: 80%;
  overflow: hidden;
}