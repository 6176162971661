.CreditReport_con {
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 25px;

    .CreditText {
        font-family: Lufga;
        font-size: 22px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: #040F2D;
    }

    .ExportButton {
        background-color: #187C8B;
        font-family: Lufga;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 10px;

    }

    .bannersize {
        background-color: #F5F6FA;
        border-radius: 8px;
        height: 200px;
        display: flex;
        justify-content: space-between;
    }

    .BnnerFirstText {
        font-family: Lufga;
        font-size: 26px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #187C8B;
    }

    .BnnersecondtText {
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #000000;
    }

    .VerfiedButton {
        font-family: Lufga;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        background-color: #009252;
        border: none;
        border-radius: 71px;
        padding: 12px 24px;
        color: white;
    }

    .NumberText {
        font-family: Lufga;
        font-size: 40px;
        font-weight: 500;
        line-height: 80px;
        letter-spacing: 0.012em;
        text-align: text;
        color: #202224;

    }

    .scoreText {
        font-family: Lufga;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #000000;
    }

    .box1 {
        background-color: #EBFFED;
        width: 220px;
        height: 60px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box2 {
        background-color: #FFE6E4;
        width: 220px;
        height: 60px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box3 {
        background-color: #F4EBFF;
        width: 220px;
        height: 60px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boxText {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: 000000;

    }

    .boxnumberText {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #187C8B;

    }

    .RemarksText {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
color: #101828;




    }

    .lastHeading {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;
    }

    .lastHeading1 {
        font-family: Lufga;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;
    }
    .ProfileImg{
        width: 150px;
        height: 150px;
    }

}