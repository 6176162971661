.BidingText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.PlusIcon {
    border: 1px solid #187C8B;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
}

.NumberText {
    font-family: Lufga;
    font-size: 36px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #202224;
}

.bidInput{
    border: none !important;
    color: black !important;
    width: 140px;
    border-radius: 0;
    font-size: 36px;
    font-weight: 600 !important;
    text-align: center;
}
.bidInput:enabled:focus{
    border: none !important;
    box-shadow: none !important;
}

.cancelButton {
    border: none;
    padding: 8px 24px;
    border-radius: 8px;
    background-color: white;
    color: #101828;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #101828;
}

.BidButton {
    border: none;
    padding: 8px 24px;
    border-radius: 8px;
    background-color: #187C8B;
    color: white;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}
.bgImg{
    background: url(/public/images/image_2024_03_05T12_37_23_785Z.png);
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    /* border-left:3px solid rgba(24, 124, 139, 1);
    border-right: 3px solid rgba(24, 124, 139, 1); */
    border-top:3px solid rgba(24, 124, 139, 1);
    border-bottom: 3px solid rgba(24, 124, 139, 1); 
}
.heading{
    font-size: 30px;
    font-weight: 500;
    color: black;
    text-align: center;
    font-family: Lufga;
}
.heading1{
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-align: center;
    font-family: Lufga;
}