.height_for_scroll {
    display: flex;
    flex-direction: column;
    height: 0px;
    width:0px;
    overflow: hidden;

    .maincontainer {
        /* width: 100%;  */
        height: auto;
        position: relative;
        padding: 25px;
        padding-bottom: 100px;
        /* background: linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%); Removed quotes and added a semicolon */
        /* margin: 0 auto; */
        /* Uncomment if you need centered alignment */
    }

    .topBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 17px;
        background: rgba(24, 124, 139, 1);
    }

    .leftBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 350px;
        background: rgba(24, 124, 139, 1);
    }

    .TopRight {
        margin-right: 10rem;
    }

    .topId {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .topText {
        font-weight: 600;
        font-size: 14px;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
    }

    .heading {
        margin-top: 30px;
        font-weight: 600;
        font-size: 18px;
        color: rgba(24, 124, 139, 1);
        font-family: 'Lufga';
        border-bottom: 2px solid rgba(24, 124, 139, 1);
        width: 220px;
        padding-bottom: 3px;
    }

    .topRightList {
        line-height: 2rem;
    }

    .listHeading {
        font-size: 14px;
        font-weight: 600;
        font-family: 'Lufga';
    }

    .listText {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Lufga';
    }

    .containerHeading {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Lufga';
    }

    .labelHeading {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Lufga';
    }

    .tableStyle {
        font-family: 'Lufga';
        font-size: 16px;
        background: none;
    }

    .styleFather {
        width: 20%;
        padding: 0.6rem 0;
    }

    .inputBrother {
        text-align: center;
        width: 90%;
        height: 1.8rem;
        border-radius: 0.4rem;
        border: 1px solid rgba(134, 132, 134, 0.5);
        /* Corrected alpha channel syntax */
        background: none;
    }


    .selectStyleFt {
        border: 1px solid rgba(134, 132, 132, 0.5);
        width: 30%;
        height: 1.8rem;
        text-align: center;
        border-radius: 0.4rem;
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    .selectStyleIn {
        border: 1px solid rgba(134, 132, 132, 0.5);
        /* Corrected alpha channel syntax */
        width: 30%;
        height: 1.8rem;
        text-align: center;
        border-radius: 0.4rem;
        font-size: 1rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    .styleAge {
        width: 10%;
    }

    .inputAge {
        width: 3.5rem;
        height: 1.8rem;
        text-align: center;
        border-radius: 0.4rem;
        border: 1px solid rgba(134, 132, 134, 0.5);
        /* Corrected alpha channel syntax */
        background: none;
    }

    .styleAlive {
        width: 20%;
    }

    .inputRadio {
        margin: 0 0.6rem;
    }

    .styleProfession {
        width: 25%;
    }

    .inputProfession {
        width: 80%;
        height: 1.8rem;
        text-align: center;
        border-radius: 0.4rem;
        border: 1px solid rgba(134, 132, 134, 0.5);
        /* Corrected alpha channel syntax */
        background: none;
    }

    .tableRounded {
        border: 2px solid black;
        /* Uncommented border */
        border-radius: 10px;
        overflow: hidden;
    }

    .styleBody {
        font-size: 14px;
        font-family: 'Lufga';
    }

    .styleLeft {
        width: 50%;
    }

    .styleRight {
        padding-left: 2rem !important;
    }

    .heightStyle {
        width: 25%;
    }

    .heightStyleBrother {
        width: 28%;
    }

    .nameStyleBrother {
        width: 28%;
    }

    .ageStyleBrother {
        width: 12%;
    }

    .professionStyleBrother {
        width: 32%;
    }

    .rightBox {
        position: absolute;
        bottom: 90px;
        right: 0;
        width: 17px;
        height: 100px;
        background: rgba(24, 124, 139, 1);
    }

    .bottomBox {
        position: absolute;
        bottom: 90px;
        right: 0;
        width: 350px;
        height: 17px;
        background: rgba(24, 124, 139, 1);
    }

    .imgWidth {
        width: 28%;
    }


    .scroll_clild_wrapper {

        margin: auto;
        max-width: 1100px;
    }

    .scroll_child {
        max-height: 100%;
        max-width: 100%;
        overflow: scroll;
    }


    .download_button_for_pdf {
        box-shadow: 2px 2px 4px #a9a9ab;
    }

    .mw_400px {
        max-width: 400px;
    }

    @media (max-width:1110px) {
        .scroll_clild_wrapper {
            margin: 10px;
            max-width: unset;
        }
    }

    @media (max-width : 768px) {
        .topText {
            font-size: 12px;
        }

        .heading {
            font-size: 16px;
        }

        .listHeading {
            font-size: 12px;
        }

        .listText {
            font-size: 12px;
        }

        .containerHeading {
            font-size: 13px;
        }

        .tableStyle {
            font-size: 12px;
        }

        .heightStyle {
            padding: 1rem 0;
        }

        .styleBody {
            font-size: 11px;
        }

    }

    @media (max-width : 576px) {
        .topText {
            font-size: 11px;
        }

        .topRightList {
            font-size: 1.2rem;
        }

        .heading {
            font-size: 13px;
        }

        .listHeading {
            font-size: 9px;
        }

        .listText {
            font-size: 9px;
        }

        .containerHeading {
            font-size: 10px;
        }

        .tableStyle {
            font-size: 9px;
        }

        .styleBody {
            font-size: 9px;
        }

        .imgWidth {
            width: 41%;
        }
    }

    @media (min-width : 1200px) {
        .tableStyle {
            font-size: 11px;
        }

        .containerHeading {
            font-size: 14px;
        }

        .imgWidth {
            width: 43%;
        }

        /* .secondCol {
        margin-left: 10px;
    } */
    }

}