.wrapper_for_embryo_donor_profile_form , .wrapper_for_Addopting_parent {
    textarea {
        resize: none;
        overflow: hidden;
    }

    .ResumeStep_con {
        /* padding: 10px ; */
        font-family: Lufga;

        .MainHeading {
            font-family: Lufga;
            font-size: 2.25rem;
            font-weight: 500;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: center;
            color: #000000;
        }

        .SubHeading {
            font-family: Lufga;
            font-size: 1.5rem;
            font-weight: 600;
            /* line-height: 48px; */
            letter-spacing: 0em;
            text-align: center;
            color: #187C8B;
            /* margin: 30px 0px; */
        }

        .LabelHeading {
            font-family: Lufga;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: left;
            color: #000000;

        }

        /* .MiddleSubHeading{
    font-family: Lufga;
font-size: 1.25rem;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
color: #000000;

} */
        .EyeView {
            position: relative;
        }

        .View {
            position: absolute;
            top: 55%;
            right: 2%;

        }

        .Horizontal {
            position: relative;
        }

        .line {
            border-top: 2px solid black;
        }

        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white;
            padding: 0 10px;
        }

        .Images_Border {
            border-style: dashed;
            color: #187C8B;

        }

        .center-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .UploadName {
            text-align: center;
            color: #000000;
            font-family: Lufga;
            font-size: 1rem;
            font-weight: 400;


        }

        .imagesCustom {
            font-size: 3rem;
            color: #187C8B;
            text-align: center;
            align-items: center;
            cursor: pointer;


        }

        .form-check-input {
            border: 1px solid rgb(177, 174, 174);
        }

        .CheckBox_parent {
            display: flex;
            margin-top: 8px;
            justify-content: space-between;

        }

        .CheckBoxParent2 {
            display: flex;
            margin-top: 8px;
            gap: 40px;
        }

        .CheckBoxParent3 {
            display: flex;
            margin-top: 5px !important;
            justify-content: space-between;
            margin-bottom: 25px;
        }

        .CheckBoxParent4 {
            display: flex;
            gap: 290px;
        }


        .ButtonForm {
            display: flex;
            justify-content: end;
            gap: 10px;
            margin-top: 30px;
        }

        .SaveButton {
            background-color: #187C8B;
            border-radius: 11px;
            padding: 8px 30px;
            color: white;
            font-weight: 500;
            text-align: center;
            border: none;
        }

        .card:hover {
            background-color: none;
        }

        .Previous {
            font-family: Lufga;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            border: none;
            color: #187C8B;
            background-color: white;

        }



        .LabelSubHeading {
            font-family: Lufga;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #101828;
        }

        .ParaSub {
            font-family: Lufga;
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.012em;
            text-align: center;

            color: #000000;


        }

        .InputMiddleHaeding {
            font-family: Lufga;
            font-size: 1.25rem;
            font-weight: 500;
            /* line-height: 48px; */
            letter-spacing: 0em;
            text-align: left;
            color: black;

        }

        p.round2 {
            border: 2px solid red;
            border-radius: 8px;
            padding: 5px;
        }


        .input-gap {
            margin-bottom: 5px;
        }

    }

    .button_section_for_upper_section {
        display: flex;
        gap: 0.2rem;
        margin-top: 1rem;
    }


    /* Dialog CSS */



    #pr_id_93_content {
        padding: 0px !important;
        margin: 0px !important;
    }

    .CongratsImage {
        background-image: url('/public/images/Frame\ 1171274974.png');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 999;
        border-radius: 30px !important;
        /* padding: 20px; */
        border: 3px solid #187C8B;
    }



    .success_of_resume_creation_dialog {
        background: url('/public/images/Frame\ 1171274974.png');
        background-size: cover;
        background-position: center;
        border-radius: 25px;
    }

    .CongratulationsText {
        font-family: Lufga;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
    }

    .CongratsImage2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .ExpolreButton {
        font-family: Lufga;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        background-color: #187C8B;
        border-radius: 12px;
        padding: 15px 30px;
        border: none;
        color: white;

    }

    .removeSiblingButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #187C8B;
        font-size: larger;
        color: white;
        font-weight: 700;
        padding: 0.2rem 1.4rem;
        border-radius: 50%;
    }


    .round2 {

        .form-label {
            margin-bottom: 0px !important;
            padding-top: 3px;
        }

    }


    .addChild_btn {
        all: unset;
        background-color: #F5F6FA;
        font-family: Lufga;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        line-height: 18.27px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        display: flex;
        padding: 12px 24px;
        align-items: center;
        border-radius: 70px;
        gap: 10px;
        justify-content: center;

    }


}

