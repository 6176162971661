
.social_pages_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
}

.social_pages_background {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 2rem;
  border-radius: 15px;
  /* height: 70vh; */
  /* margin-bottom: 8vh; */
  /* min-height: 68vh; */
  padding-left: none;
}

.nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  /* margin-top: 1.9rem; */
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.navLink {
  text-decoration: none;
  padding: 10px 20px;
  color: #333;
  position: relative;
  color: black;
  background: #fff;
  gap: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 15px 0px 0 0;
}

.count{
  background-color: white;
  color: #187C8B;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;justify-content: center;
  width: 25px;
}

.navLink::after{
  color: #fff;
  z-index: -1;
}

.active {
  z-index: 1;
  color: #fff;
  background-color: #187C8B;
}

.active::after{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 5px 10px 0 0;
  left: 10px;
  background-color: #187C8B; 
  transform: skew(10deg);
}

.searchbar{
  color:black;
  /* width: 30vw; */
  width:30%;
  height: 5vh;
  padding-left: 10px !important;
  /* margin-right: 3vw; */
}

.search{
  display: flex;
  justify-content: end;
  align-items: center;
}

.subcription_main{
  display: flex;
  /* border: 10px solid green; */
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-between;
  /* min-width: 80%; */
  position: relative;
  border-radius: 10px;
  margin: 0px 1rem;
  padding: 2rem 3rem;
  box-shadow: 0px 0px 0.5px 0.5px rgba(0, 0, 0, 0.5);   
}

.main_left{
  display: flex;
  gap:2rem;
  align-items: center;
}

.subcription_plan{
  border: 10px solid red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  
}

.subcription_plan_chart{
  border: 10px solid blue;
  display: flex;
  /* justify-content: space-between; */
  /* gap: 50px; */
  align-items: center;
  /* width: 70vw; */
  /* margin: 0px 5px; */
}

.plan{
  display: flex;
  gap: 15px;
  align-items: center;
}

.txt{
  color: #187C8B;
}

.dot{
  color: #1BA345;
  margin-left: -0.5rem;
}

.plan_details{
  /* width: 50vw; */
  min-height: 15vh;
  display: flex;
  flex-direction: column;
}

.arr{
  display: flex;
  padding: 10px 0px;
  align-items: center;
  margin-top: 0.2rem;
  gap: 0.8rem;
  /* border: 2px solid red; */
  /* width: 55vw; */
  flex-wrap: wrap;
}

.no_break{
  white-space: nowrap;
}

.subheading_color{
  color:#202224; 
}

.arr_list{
  border: 1px solid cadetblue;
  padding: 2px 14px;
  background-color: #DFE9EB;
  border-radius: 9px;
}

.features_not_included{
  display: flex;
  padding: 10px 0px;
  align-items: center;
  margin-top: 0.2rem;
  gap: 0.8rem;
  /* border: 2px solid red; */
  /* width: 55vw; */
  flex-wrap: wrap;
}
.features_not_included_list{

  border: 1px solid #DC1919;
  background: #DC19191A;
  padding: 2px 14px;
  border-radius: 9px;
}



.price{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin-left: 15px;
  /* border: 2px solid salmon; */
}

.validity{
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* display: inline; */
  margin-top: 0.5rem;
  padding: 10px;
  gap:0.6rem;
  /* background-color: aqua; */
}

.dot_icon{
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 1.5rem;
}