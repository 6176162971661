.ConfirmationText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;

}

.CitzenText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.Yesbutton {
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    color: white;
}

.Nobutton {
    background-color: white;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px 24px;
    border: none;
    border: 1px solid black;
    border-radius: 8px;
    color: black;
}


.PaymentMethodText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;


}

.GoldText {
    font-family: Lufga;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: black;

}

.CreaditCradText {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;

}

.CraeditCardDiv {
    display: flex;
    flex-direction: column;
}

.accptCrads {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
}

.CancelPayButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    padding: 21px 26px;
    border-radius: 12px;
    border: none;
    background-color: transparent;

}

.InputCustom1 {
    width: 100%;

}

.InputCustom {
    padding: 12px
}

.PayButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #187C8B;
    width: 194px;
    border-radius: 12px;
    border: none;
    color: white;

}

.EyeView {
    position: relative;
}

.View {
    position: absolute;
    top: 33%;
    font-size: 20px;
    right: 10%;


}

.AuctionSpaceButton {
    padding: 12px 24px;
    border-radius: 71px;
    color: white;
    border: none;
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    background-color: #187C8B;
}





/* yyyyyyyyyyyyyyyyyyyyyyyyyyyy  */

.cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
}

.sBtn {
    width: fit-content;
    border-radius: 7px;
    background: rgba(24, 124, 139, 0.06);
    padding: 4.37px 10.93px 4.37px 10.93px;
    color: rgba(24, 124, 139, 1);
    font-weight: 500;
    line-height: 21.85px;
    font-family: 'Poppins';
}































/* nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn  */



.card {
    border: 1px solid #E8E8EA;
}

.card:hover {
    background: unset !important;
    color: unset;
}

.ViewAllPosts{
    font-size: 16px;
    cursor: pointer;
}

.card_upper {
    /* background-position: 50% 10%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 19.86px 19.86px 0 0;
    height: 220px;
    width: 100%;
    padding: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */

    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 19.86px 19.86px 0 0;
    height: 220px;
  
    width: 100%;
    padding: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card_upper_mask{
    position: absolute;
    top:0px;
    height:100%;
    width: 100%;
    z-index:100;
    filter:blur(3px);
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .card_upper_image{
    position: absolute;
    width: 100%;
    object-fit: cover;
    top:0px;
    height:100%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index:100;
  }
.maincardContainer{
    height: 100%;
    overflow: hidden;
    border-radius: 19.86px 19.86px;
    box-shadow: 2px 2px 4px lightgray , -1px -1px 3px lightgray;
    display: flex;
    flex-direction: column;
}

.upper_v_1 {
    display: flex !important;
    justify-content: end !important;
    color: white;
    z-index: 100;

}


.upper_v_1_1 {
    width: 30px;
    height: 32px;
    background: white;
    border-radius: 8px;
    box-shadow: inset 1px 1px 2px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.upper_v_2 {


    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #6A6A6A;
    z-index: 100;


    .upper_v_2_1 {
        color: white;
        background: black;
        display: inline-block;
        padding: 0.4rem;
        border-radius: 6.56px;
        font-weight: 500;
        font-family: 'Poppins';
    }

}

.card_lower {

    padding: 1.3rem;
    background: #ffffff;
    border-radius: 0 0 19.86px 19.86px;
    /* height:auto; */
    flex-grow: 1;

    .lower_v_1 {
        font-weight: 600;
    }

    

    .lower_v_2 {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 400;
    }

    .Currenttext {
        font-family: Lufga;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Auctiontext {
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: -0.11428570002317429px;
        text-align: left;

    }

    .Dollertext {
        font-family: Lufga;
        font-size: 20px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #187C8B;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lower_v_3 {
        width: 100%;
        height: 35px;
        border-radius: 7.99px;
        background: #187C8B;
        color: white;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .lower_v_Buy {
        width: 100%;
        height: 35px;
        border-radius: 7.99px;
        /* background: #187C8B; */
        border: 1px solid #187c8b;
        color: rgb(0, 0, 0);
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
    }

    .lower_v_Surro {
        color: #187C8B;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        /* margin-left: 10px; */
        margin: 10px;

    }
}

.card_lower_v_1{

}

.footer .lufga_16px {
    line-height: 1.7rem;
}

.middle_lower {
    display: flex;
    justify-content: center;

}

.middle_lower button {
    background: white;
    border-radius: 25px;
    border: none;
    color: #187c8B;
    font-size: 1rem;
    padding: 10px 50px;
    box-shadow: 0px 3px 14px 0px rgba(77, 76, 76, 0.25) !important;
    font-weight: 600;
}


.header {
    font-size: 36px;
    font-weight: 600;
    color: black;
    text-align: center;
}

.discription {
    font-size: 24px;
    color: #187c8B;
    text-align: center;
}
.mainTable tbody tr{
    border-collapse: separate;
    border-spacing: 0 0.3em;
}

.amountLable {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.cancelbtn {
    color: #187c8B;
    padding: 15px 30px;
    font-size: 18px;
}

.cancelbtn:hover {
    color: #187c8B;
}

.paybtn {
    color: white;
    padding: 15px 30px;
    background: #187c8B;
    border-radius: 12px;
    font-size: 18px;
}

.paybtn:hover {
    color: white;
    background: #187c8B;
}


@media (max-width: 1370px) {
    .cardContainer {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}
