.Planname {
  display: flex;
  align-items: center;
  margin: 1rem 0px;
  width: 100%;
  gap: 3rem;
}

.child_plannane {
  width: 50%;
}

.child_plannane_dropdown {
  width: 100%;
}

.input_text {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* background-color: red; */
  /* width: 100%; */
  /* width: 22vw; */
  padding: 7px;
  margin-bottom: 1rem;
  border: 0.1px solid #e9e9f0;
  border-radius: 5px;
}

.Images_Border {
  /* border-style: dashed; */
  /* border:1px dashed #187C8B; */
  border-spacing: 500px;
  margin-top: 5rem;
  /* color: #187C8B; */
  background-color: #ffffff !important;
  width: 100%;
  height: 20%;
  border-radius: 10px;
  background: linear-gradient(to right, #187c8b 50%, transparent 0%) top
      repeat-x,
    linear-gradient(#187c8b 50%, transparent 0%) right repeat-y,
    linear-gradient(to right, #187c8b 50%, transparent 0%) bottom repeat-x,
    linear-gradient(#187c8b 50%, transparent 0%) left repeat-y;
  background-size: 20px 2px, 2px 10px;
}

.cancelBtn {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #F5F6FA;
  color: #187c8b;
  border-radius: 12px;
  border: none;
}

.SaveButton {
  width: 193px;
  height: 65px;
  color: white;
  border-radius: 12px;
  border: none;
  background-color: #187c8b;
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.center_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  /* justify-content: center; */
}

.imagesCustom {
  font-size: 3rem;
  color: #187c8b;
  text-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.UploadName {
  text-align: center;
  color: #000000;
  font-family: Lufga;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 10px;
}

.uploadImg{
  min-width: 200px;
  height: 200px;

}

.crossMain{
  position: relative;
}

.crossIcon{
  /* z-index: 999999; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3px;
  top: 3px;
  padding: 8px;
  /* margin: 3px; */
  width: 32px;
  height: 32px;
  font-weight: bolder !important;
  border-radius: 50%;
  background-color: white;
  color: red;
}

.iconsize{
  font-size: larger !important;
  font-size: 500px !important;
  font-weight: bolder !important;
}

.ProfileImg1 {
  width: 171px;
  height: 171px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.inputIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  background: #00000063;

}