.p-dialog-content {
  border-radius: 6px;
  padding: 10px 40px;
}
.HeaderDialog {
  width: 100%;
  background-color: #f5f6fa;
  padding: 20px;
}
.PrifileHeding {
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  padding-left: 20px;
}
.VerfiedButton1 {
  font-family: Lufga;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.22500000894069672px;
  text-align: left;
  background-color: #009252;
  color: white;
  padding: 8px 24px;
  border-radius: 71px;
  border: none;
}
.Textname {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: black;
}

.cancelBtn {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background-color: white;
  color: #de3e44;
  border-radius: 12px;
  border: none;
}

.input_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px;
  margin-bottom: 1rem;
  border: 0.1px solid #e9e9f0;
  border-radius: 5px;
}

.ProfileText {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6a6a6a;
  display: flex;
  justify-content: center;
}
.ViewResumeButton {
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  border: none;
  border-radius: 71px;
  padding: 12px 24px;
  background-color: #187c8b;
  color: white;
}
.SaveProfileButton {
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  border: none;
  border-radius: 71px;
  padding: 12px 24px;
  background-color: #f5f6fa;
  color: #202224;
}
.LabelHeading {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #101828;
}

.LabelText {
  font-family: Lufga;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #101828;
}
.LoctionText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #202224;
}
.CurrentText {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #000000;
}
.CurrentText1 {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #000000;
}
.InputCustom {
  background-color: #e8e8ea;
}

.ProfilePic {
  /* height: 100%; */
  width: 76%;
}

.ProfilePic img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.CancelButton {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background-color: white;
  color: #101828;
  border-radius: 12px;
  border: none;
}

.SaveButton {
  width: 193px;
  height: 65px;
  color: white;
  border-radius: 12px;
  border: none;
  background-color: #187c8b;
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.Images_Border {
  /* border-style: dashed; */
  /* border:1px dashed #187C8B; */
  border-spacing: 500px;
  /* color: #187C8B; */
  display: flex;
  flex-direction: column;
  margin: 0px 19px;
  width: 97%;
  height: 20%;
  border-radius: 10px;
  background: linear-gradient(to right, #187c8b 50%, transparent 0%) top
      repeat-x,
    linear-gradient(#187c8b 50%, transparent 0%) right repeat-y,
    linear-gradient(to right, #187c8b 50%, transparent 0%) bottom repeat-x,
    linear-gradient(#187c8b 50%, transparent 0%) left repeat-y;
  background-size: 20px 2px, 2px 10px;
}
.center_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  justify-content: center;
}

.imagesCustom {
  font-size: 3rem;
  color: #187c8b;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.UploadName {
  text-align: center;
  color: #000000;
  font-family: Lufga;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 10px;
}