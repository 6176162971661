.ViewALL{
    background: white;
    padding: 3%;
    border-radius: 23px;
}

.HeaderDialog {
    width: 100%;
    background-color: #F5F6FA;
    padding: 20px 30px;

}

.ProfilePic{
    width: 187px;
    height: 187px;
    border-radius: 100%;
    overflow: hidden;
}

.PrifileHeding {
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    padding-left: 20px;
}

.VerfiedButton1 {
    font-family: Lufga;
    font-size: 11.55px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.26px;
    text-align: left;
    background-color: #009252;
    color: white;
    padding: 9.24px 27.73px;
    border-radius: 71px;
    border: none;
}

.Textname {
    font-family: Lufga;
    font-size: 27.7px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: black;

}

.ProfileText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: #6A6A6A;
}

.ViewResumeButton {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #187C8B;
    color: white;

}

.SaveProfileButton {

    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #F5F6FA;
    color: #202224;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;
    margin-bottom: 15px;
}

.HistoryText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;

}

.MiddleSubHeading {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: black;

}

.SubLAbelHedaing {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;

}

.child1::placeholder {
    background-color: red;
}

.InputPlaceholder::placeholder {
    color: red;
    /* Set the color of the placeholder text */
    opacity: 1;
    /* Ensure the placeholder text is fully visible */
}

.LoctionText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
}

.card_wrapper a {
    text-decoration: none;
    color: unset;
}

.card {
    border: 1px solid #E8E8EA;
    border-radius: 22px !important;
}

.card:hover {
    background: unset !important;
    color: unset;
}

.CurrentText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.CurrentText1 {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.widButton {
    background-color: #187C8B;
    padding: 20px 0;
    border-radius: 6px;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    border: none;
    flex-grow: 1;
}

.BuyNowButtom {
    border: 1px solid #187C8B;
    border-radius: 6px;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #202224;
    flex-grow: 1;
    padding: 20px 0;
    background-color: white;
}

.crossButton {
    color: #187C8B;
}

.InputCustom {
    margin-bottom: 20px;
}