.rs_container {
    width: 100%;
    height: auto;
    background: linear-gradient(311.07deg, #FFFFFF 35.06%, #E8F9FA 70.96%);

}

.resume_inner_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 10%;
}

.resume_top_left {
    width: 176px;
}

.resume_top_left img {
    width: 100%;
}

.v_nameHeading {
    font-size: 32px;
    font-weight: 600;
    color: black !important;
    line-height: 40px;
    text-align: center;
}

.v_lowerHeading {
    color: rgba(24, 124, 139, 1);
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
}

.resume_middle_section {
    width: 100%;
    display: flex;

}

.v_middle_left {
    width: 50%;
    /* border: 1px solid red; */
    padding-right: 50px;
    /* padding-top: 20px; */
    height: 450px;
    position: relative;
}

.v_middle_right {
    width: 50%;
    /* border: 1px solid blue; */

}

.v_back_card{
  width: 90%;
  border-radius: 0 24px 24px 0;
  background: rgba(24, 124, 139, 1);
  height: 100%;

}

.v_back_card_img{
  width: 75%;
  height: 92%;
  position: absolute;
  top: 4%;
  left: 60px;
  border-radius: 30px;
  overflow: hidden;
}

.v_back_card_img img{
    width: 100%;
    height: 100%;
}

.v_top_heading_container{
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 0 0 0 10%;
}

.v_top_heading{
   width: 100%;
   background: rgba(250, 187, 182, 1);
   border-radius: 14px 0 0 14px;
   padding: 15px;
   text-align: center;
   font-weight: 600;
   font-size: 17px;
   line-height: 17px;
   color: black !important;
}

.v_text_containers{
  width: 100%;
  padding-top: 25px;
}

.v_table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
}

.v_middle_heading{
    background: rgba(24, 124, 139, 1);
    color: white !important;
    font-size: 17px;
    font-weight: 500;
    width: fit-content;
    padding: 15px 45px;
    border-radius: 16px;
    margin: 40px auto;
}

.v_lower_container{
    width: 70%;
    padding-left: 10%;
    padding-bottom: 50px;
}