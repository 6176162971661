.ViewButton {
    border: none;
    border-radius: 12px;
    background: #C5DEE2;
    color: black;
    padding: 8px 20px;
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.27px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    display: flex;
    align-items: center;

}

.ViewButton:disabled,
.ViewButton[disabled]{
  border: 1px solid #faf8f8;
  background: #faf8f8;
  color: #666666;
}

.IconCustom {
    font-size: 10px;
}

.AddDocumnentButton {
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.49px;
    text-align: center;
    border: none;
    padding: 10px 24px;
    border-radius: 12px;
    color: white;
}

.AssingedTex {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #222222;
    font-family: Lufga;
}

.AddPocText {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #187C8B;
    font-family: Lufga;

}


.searchbar {
    color: black;
    padding-left: 10px !important;
}

.ProfileImages{
    min-width: 50px;
    /* width:50px !important; */
    height:50px;
    overflow: hidden;
}

.ProfileImages img{
    height:50px;
    border-radius: 50%;
}

.block_style{
    position: relative;
    width:50px !important;
    height:50px;
    overflow: hidden;
}

.block_style::before{
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    bottom:0px;
    right:0px;
    background:url("/public/images/Vector.png");
}

