.image_section{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.image_section img{
    width: 35%;
}

.content_section{
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
}

.lufga_20px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;

}

.custom_gray{
    color: #97989F;
    }
    
    .theme_color{
        color: #187c8b;
        }