@font-face {
  font-family: "DM Sans";
  src: url("assests/fonts/DMSans-Regular.ttf");
}

@font-face {
  font-family: "GorgiaEstate";
  src: url("assests/fonts/GeorgiaEstate-w15Mn.ttf");
}

@font-face {
  font-family: "Lufga";
  src: url("../public/fonts/Lufga-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

body {
  margin: 0;
  font-family: 'Lufga' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  /* overscroll-behavior: none !important; */
  overflow: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

.align_center {
  align-items: center;
}

.justify_center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.no_underline {
  text-decoration: none;
}

/* [[[[[{{{{{{{{{{{===========  input css  ==========}}}}}}}}}}}}}}]]]] */

.custom_input {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  color: var(--PT-80, #363f57);
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.custom_input:focus {
  outline: 2px solid var(--CTA, #2ac37d);
  box-sizing: border-box;
}

.invalid_input {
  border: 2px solid red !important;
}

.error {
  color: red !important;
}

.active>div {
  /* color: var(--PT-5, #F2F3F4); */
  border-radius: 10px;
  padding: 0.4rem;
  background: #187c8b;
}


.main_color {
  color: #187c8b;
}

.ck.ck-editor__editable {
 height: calc(100vh - 360px);
}

.ck.ck-powered-by {
  display: none !important;
}


.active>div div {
  color: white;

}










/* +++++++++++++++++++++primereact classess css start ++++++++++++++++++++ */
.p-dialog-content {
  padding: 40px;
}

.p-dialog-header {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

/* +++++++++++++++++++++primereact classess css end ++++++++++++++++++++ */
.subheading_color{
  color:#202224 !important; 
}
.TabviewContainer{
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1 !important;
    display: block !important;
    position: absolute !important;
    bottom: 0 !important;
    height: 2px !important;
    background-color: white !important;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
  }
  
  .p-tabview .p-tabview-nav li .p-highlight .p-tabview-nav-link {
    background: #ffffff !important;
    border-color: #187C8B !important ;
    
    z-index: 3 !important;
  }
  
  .p-tabview-nav {
    box-shadow: none !important ;
  }
  
  .p-tabview .p-tabview-nav {
    background: #ffffff !important;
    
    border-width: 0 0 0px 0 !important;
  }
  
  .p-progressbar .p-progressbar-value {
    border: 0 none !important;
    margin: 0 !important;
    background-color: red !important;
  }
  a{
    text-decoration: none !important;
    color: rgb(136, 133, 133) !important;
  }
  .p-highlight a{
    background-color:unset !important;
    color: #187C8B !important;
}
  

}






.btn_secondary {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Main, #101828);
  color: var(--Secondary-Main, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.MuiTablePagination-selectLabel {
  margin: 0px;
}

.MuiTablePagination-displayedRows {
  margin: 0px;
}

body {
  font-family: "Lugfa", sans-serif;
}

.main_div {
  display: block;
  overflow-x: hidden;
  width: 100%;
}

.chienese_page {
  display: none;
  overflow-x: hidden;
  width: 100%;
}

.upper_section {
  display: flex;
  background-position: center;
  /* height: 100vh; */
}

.upper_section_image_couple {
  width: 55%;
  /* border: 1px solid red; */
}

.upper_section_image_couple img {
  width: 100%;
  /* height: 90%; */
}

.upper_section_content {
  padding: 0 0 0 6%;
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  background-image: radial-gradient(circle at -40% center,
      rgba(213, 174, 228, 1) 0%,
      white 50%);
  /* border: 1px solid red; */
}

.button_section_for_upper_section {
  display: flex;
  gap: 0.2rem;
  margin-top: 1rem;
}


.button_section_for_upper_section :nth-child(1) {
  background: #187c8b;
  border: none;
  border-radius: 11.36px;
  color: white;
  padding: 0.8rem 1.6rem;
  font-family: "Lufga", sans-serif;
}

.button_section_for_upper_section :nth-child(2) {
  background: transparent;
  border: none;
  border-radius: 11.36px;
  padding: 0.8rem 1.6rem;
  font-family: "Lufga", sans-serif;
}

.all_stories {
  padding: 0 4.875rem 6.875rem 4.875rem;
}

.card {
  border: none;
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14.02px 14.02px 21.03px 14.02px;
  border-radius: 14.02px;
  gap: 21.03px;
  box-shadow: 0px 3.5054876804351807px 14.021950721740723px 0px rgba(158, 158, 158, 0.25);
}

.card-img-top {
  border-radius: 16px 16px 0px 0px;
}

.body_of_card {
  display: flex;
}

.card-body {
  padding: 0;
}

.card:hover {
  background: rgba(24, 124, 139, 1) !important;
  cursor: pointer;
  color: white;
}

.all_stories a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

/* .col-md-6,
.col,
.col-6,
.col-md-12,
.col-md-4,
.col-12 {
  padding: 1rem;
} */


.BackPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}



.choose_us_section {
  padding-left: 4rem;
  display: flex;
  padding-bottom: 6rem;
}

.text_section_of_choose {
  width: 50%;
  padding: 1rem 4rem 1rem 0;
  /* border: 2px solid green; */
}

.text_content_of_choose {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.text_content_of_choose>div {
  cursor: pointer;
}

.text_content_of_choose>div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.text_content_of_choose>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text_content_of_choose>div:nth-child(2)>div {
  padding: 8px 24px 8px 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text_content_of_choose>div:nth-child(2)>div:hover {
  box-shadow: 0px 16px 24px 0px rgba(96, 97, 112, 0.12),
    0px 2px 8px 0px rgba(40, 41, 61, 0.02);
}

.great_sign {
  font-weight: 600;
  display: inline-flex;
  transform: translate(3px, 2px);
  cursor: pointer;
}

.image_section_of_choose {
  width: 50%;
  /* border: 2px solid red; */
}

.image_section_of_choose img {
  margin-top: 83px;
  height: 80%;
  width: 100%;
}

.footer {
  display: flex;
}

.left_footer {
  background: url("https://eduma.io/wp-content/uploads/2024/02/image_2024_02_09T10_54_13_894Z.png");
  background-size: 100% 100%;
  background-position: center;
  width: 60%;
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* gap:2rem; */
}

.right_footer {
  background: url("https://eduma.io/wp-content/uploads/2024/02/image_2024_02_09T10_54_25_293Z.png");
  background-size: 100% 100%;
  background-position: center;
  width: 40%;
  padding: 10% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 2rem;
}

.left_lower_footer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.left_right_section_footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.left_right_section_heading_footer {
  font-family: "Lufga", sans-serif;

  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.left_right_section_content_footer {
  font-family: "Lufga", sans-serif;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.right_footer div,
.right_footer a {
  color: white;
}

.right_upper,
.right_middle,
.right_lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.middle_upper input {
  width: 100%;
  border-radius: 6px;

  padding: 10px 0;
  padding-left: 16px;

  box-shadow: none;
  border: none;
}

.lower_lower {
  display: flex;
  gap: 0.7rem;
}

.lower_lower a img {
  width: 100%;
}

.lower_lower img {
  width: 12%;
  cursor: pointer;
}

.lower_upper {
  font-size: 1.5rem;
}

.no_style {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.p-relative {
  position: relative !important;
}

.viewPasswordIcon{
  position: absolute;
  top: 7px;
  right: 12px;
}

@media (max-width: 1000px) {
  :root {
    font-size: 14px;
  }

  .upper_section {
    flex-direction: column-reverse;
    /* margin-top: 30rem; */
  }

  .upper_section>div {
    width: 100%;
  }

  .footer {
    flex-direction: column;
  }

  .footer>div {
    width: 100%;
  }

  .left_lower_footer div {
    font-size: 1rem !important;
  }

  .left_lower_footer {
    margin-top: 4rem;
  }

  .choose_us_section {
    flex-direction: column-reverse;
  }

  .text_section_of_choose,
  .image_section_of_choose {
    width: 100%;
  }

  .left_upper_footer {
    margin-top: 2rem;
  }
}

@media (max-width: 800px) {
  .upper_section_content {
    background: transparent !important;
    margin-bottom: 7rem;
  }

  .all_stories {
    padding: 0 5%;
  }

  /* .upper_section {
      margin-top: 25rem;
  } */
}

@media (max-width: 450px) {
  :root {
    font-size: 12px;
  }

  /* .navbar-nav {
      font-size: 1.5rem;
  } */

  .nav_list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .upper_image_section {
    flex-direction: column-reverse;
    padding: 4rem 0;
    gap: 5rem;
  }

  .img_of_upper_section {
    display: flex;
    justify-content: center;
  }

  .img_of_upper_section img {
    width: 50%;
  }

  .card-body .lufga_18px {
    font-size: 1.135rem !;
  }

  .middle_upper input {
    padding: 12px;
    padding-left: 1rem;
    font-size: 2rem;
  }

  .middle_upper input::placeholder {
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .choose_us_section .lufga_16px_bg_gray {
    font-size: 1.25rem;
    line-height: 1.8rem;
  }

  .choose_us_section .lufga_20px {
    font-size: 1.55rem;
    line-height: 2rem;
  }

  .middle_lower button {
    margin-top: 1rem;
    font-size: 2rem;
  }

  button:disabled {
    background-color: transaprent !important;
    border-color: transparent !important;
    color: #777 !important;
  }

  .image_section_of_header {
    display: flex;
    /* gap: 0.8rem; */
  }

  .navbar-collapse {
    margin: 2rem 0 0 2rem;
  }
}

@media (max-width: 361px) {
  :root {
    font-size: 8px;
  }
}

.text-primary {
  color: #187C8B;
}



.multiSelectDropDown {
  width: 100%;
  padding: 10px auto !important;
}

.p-icon {
  border: none !important;
  color: black;
  background: transparent !important;
  font-size: 16px !important;
}

.p-multiselect .p-multiselect-trigger {
  width: 1.4rem !important;
  margin-right: 8px !important;
}

.p-disabled,
.p-disabled * {
  cursor: default;
  pointer-events: none;
  user-select: none;
  background: #e9ecef !important;
  opacity: 1;
}



.p-multiselect-label {
  color: black !important;
  font-size: 16px !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 2px 0.75rem !important;
  margin-right: 0.5rem;
  background: #e5e7eb;
  color: #4b5563;
  border-radius: 16px;
  font-size: 12px !important;
}

.p-multiselect-token-icon {
  font-size: 12px !important;
}

.p-checkbox-icon {
  color: white !important;
  width: 2rem !important;
  font-size: 1.5rem !important;
}


/* input[type=text]:focus{
  border: 1px solid rgb(177, 174, 174) !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  outline: 0;
}
input[type=text]:hover{
  border: 1px solid rgb(177, 174, 174) !important;
} */
.btn_fill {
  color: white;
  background: rgba(24, 124, 139, 1);
  border: 1px solid rgba(24, 124, 139, 1);
  border-radius: 12px;
  padding: 20px 70px;
  font-weight: 500;
  font-size: 18px;
}

.btn_non_fill {
  background: none;
  color: rgba(24, 124, 139, 1);
  border: none;
  border-radius: 12px;
  padding: 20px 50px;
  font-size: 18px;
  font-weight: 600;
}



@media screen and (max-width: 800px) {
  .btn_fill {
    color: white;
    background: rgba(24, 124, 139, 1);
    border: 1px solid rgba(24, 124, 139, 1);
    border-radius: 12px;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 17px;
  }

  .btn_non_fill {
    background: none;
    color: rgba(24, 124, 139, 1);
    border: none;
    border-radius: 12px;
    padding: 10px 40px;
    font-size: 17px;
    font-weight: 600;
  }
}

/* .multiSelectDropDown .p-multiselect-checkbox input[type="checkbox"]:checked + .p-checkbox-box {
  background-color: red !important;
  border-color: green !important;
} */


.p-multiselect-item .p-highlight {
  background-color: #187C8B
}



.style_container {
  font-family: Lufga;
  height: 100%;

  .category-slider-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 290px;
    margin: 0 auto;
    background: white;
    height: 100%;
    /* gap: 24px; */
    box-shadow: 0px 2px 23px -14px #000;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
  }

  .textSubs {
    font-family: Lufga;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    padding: 10px 51px;

  }

  .CardCustom {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .features_card {
    max-height: 350px;
    overflow: auto;
  }

  .PopularPlan {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px, 12px, 0px, 0px;
    Padding: 7px, 0px, 6px, 0px;
  }

  .popularplanparent {
    position: relative;
  }

  .PopularPlan2 {
    background-image: linear-gradient(to right, #0592A7, #072327);
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    border-radius: 8px 8px 0px 0px;
    display: block;
    padding: 7px 0px 6px 0px;
    position: absolute;
    width: 100%;
  }

  .bi-check2 {
    background-color: #0592A7;
    padding: 0px 2px;
    border-radius: 50%;
    color: white;
  }

  .bi-check2::before {
    margin-bottom: 2px;
  }

  .bi-x {
    background-color: #FFB6D9;
    padding: 0px 2px;
    border-radius: 50%;
    color: white;
  }

  .bi-x::before {
    margin-bottom: 2px;
  }

  .PopularPlan3 {
    padding: 7px 0px 6px 0px;
  }

  .FreeText {
    font-family: Lufga;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: black;

  }

  .dollerText {
    font-family: Lufga;
    font-size: 1.4em;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #156CFE;
    display: flex;
    justify-content: center;
    gap: 2px;

  }

  .CompleteFreeText {
    font-family: Lufga;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 18px;
    color: #2D2D2D;
    text-align: center;

  }

  .SupportText {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;

  }

  .CurrentPlantText {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 7px 0px;
    border-radius: 5px;
    color: white;
    background-image: linear-gradient(to right, #0592A7, #072327);
    border: none;

  }

  .SelectPlantText {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 7px 0px;
    border-radius: 5px;
    color: white;
    background-color: #000000;
    border: none;
  }

  .SelectPlantTextfree {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding: 7px 0px;
    border-radius: 5px;
    color: white;
    background: linear-gradient(270deg, #187C8B 5.42%, #083036 95.78%);
    border: none;
  }

  .BasicPlanText {
    overflow: auto;
    height: 40px;
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }

  .FeaturesText {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;

  }

  .FeaturesMain {
    background-color: #E8E8E8;
    border-radius: 3px 0px;
  }

  .TextICon {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #454545;
    ;

  }


  .MonthlyText {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
  }

  .OffText {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: center;
    padding: 3px 12px;
    border: 1px solid black;
    align-items: center;
    border-radius: 56px;
    display: flex;
    justify-content: center;
  }

}

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mainContainer {
  width: 100%;
  padding: 2.5rem;
  /* height: calc(100vh - 75px); */
  /* overflow: hidden; */
  overflow: auto;
  /* height: 100vh; */
  /* border: 2px solid red; */
}
.CardStyle {
  border-radius: 16px !important;
  border: none !important;
  background-color: #ffffff !important;
}

.searchInput {
  position: relative;
  width: 60%;
}
.searchIcon {
  position: absolute;
  top: 12px;
  left: 10px;

}