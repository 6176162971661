.main_contianer{
    height: 4rem;
    gap:8px
}

.active{
    background: #00B69B1A;
    display: flex;
    gap:0.4rem;
    align-items: center;
    justify-content: center;
    padding:0.4rem 1rem;
    color: #00B69B;
    border-radius: 8px;
    min-width: 100px;
    cursor:pointer;
}

.block{
    background: #DC19191A;
    COLOR: #DC1919;
    display: flex;
    gap:0.4rem;
    align-items: center;
    justify-content: center;
    padding:0.4rem 1rem;
    border-radius: 8px;
    min-width: 100px;
    cursor:pointer;
}

.delete_button{
    margin-top: 5px;
}

.icons_margin{
    margin-top:4px;
}