/* {{{{{{{{{{{{{{============= css for logo component =============}}}}}}}}}}}}}}}}}]]]]] */
.logo_con {
    width: fit-content;

    .logo_text {
        color: var(--White, #FFF);
        font-family: Georgia;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-left: 16px;
    }
}
.p-dialog-content{
    padding: 30px;
    border-radius: 6px;
}


.p-accordion-content{
    padding: 0;
    border: none;
}
.p-accordion-header{
    border-radius: 8px;
}
.p-highlight a{
    background-color: #187C8B !important;
    color: white !important;
}

.p-accordion-header a{
    background-color: #DCE7EC;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;  
    font-family: Lufga;
font-size: 20px;
font-weight: 400;
line-height: 31.32px;
letter-spacing: -0.11428570002317429px;
text-align: left;
display: flex;
flex-direction: row-reverse;

}

.p-icon{
    box-sizing: content-box;
    background: white;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid skyblue;
    color:black;
}

.p-accordion-tab-active , .p-accordion-tab{
    display: flex;
    flex-direction: column;
    gap:1rem;
    transition: ease-in-out all;
}


.p-accordion{
    display: flex;
    flex-direction: column;
    gap:1rem;
}


.p-accordion-content{
    background-color: #F5F6FA;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    border: none;
}


.p-accordion-content .p-accordion{
    gap:0rem;
}

.p-accordion-content .p-accordion .p-accordion-content{
    border-radius: 0px;
    margin-top: 0px;
}

.p-accordion-content .p-accordion .p-accordion-content .p-accordion-tab{
    margin-top: 0px !important;
}


.p-accordion-content .p-accordion .p-accordion-tab{
    margin-bottom: 1.5px;
    border-radius: 0px;
}

.p-accordion-content .p-accordion .p-accordion-tab .p-accordion-header a{
    background: white;
    border-radius: 0px;
}

.p-accordion-content .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link{
    background: white !important;
    color:black !important;
}

.p-accordion-content .p-accordion .p-accordion-tab,.p-accordion-content .p-accordion .p-accordion-tab-active{
    gap: 0rem;
}


.p-accordion-content .p-accordion .p-accordion-tab-active .p-accordion-header-text{
    color:#187C8B; 
}


.p-inputtext{
    padding: 5px 30px;
    /* width: 300px; */
    border-radius: 30px;
}

.cancelBtn {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: transparent;
    color: #187c8b;
    border-radius: 12px;
    border: none;
    width: 120px;
    height: 50px;
  }
  .cancelBtn:hover{
    border: 1px solid #187c8b;
  }



.form-check-input:checked[type=radio]{
    background-color:#187C8B;
}
/* {{{{{{{{{{============  login auth template css  ===============}}}}}}}}}} */
.login_auth_template_main {
    height: 100vh;
    overflow: auto;
    display: flex;
    background: var(--Dark-Base-Blue, #002356);

    .left {
        width: 60%;
        max-height: 100vh;
        overflow: auto;
        padding: 20px 0px;

        /* background-image: url("../../assests/images/loginLeft.jpg"); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0px 25px 25px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::-webkit-scrollbar {
            width: 0px;
        }


        .left_text {
            width: 70%;
            color: var(--Text, #040F2D);
            text-align: center;
            font-family: Georgia;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 156.123%;
        }
    }


    .right {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;

        .right_content {
            width: 85%;

        }

    }

    @media (max-width:900px) {
        .left {
            width: 50%;

            .left_text {
                font-size: 20px;
            }
        }

        .right {
            width: 50%;

            .right_content {
                width: 95%;

            }
        }
    }

    @media (max-width:400px) {
        .left {
            width: 0%;

        }

        .right {
            width: 100%;

        }
    }

}


/* {{{{{{{{{{{{{{{{===========  toast css   ============}}}}}}}}}}}}}}}} */
.customToast_main_con {
    .error {
        color: #EF4444;
        background-color: #ef444449;
    }

    .success {
        background-color: #20be5a4f;
        color: #20BE5A;
    }

    .customToast_con {
        margin-top: 10px;
        height: 80px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .message_con {
            display: flex;
            height: 100%;
            align-items: center;
            column-gap: 10px;

            .error_item {
                height: 100%;
                background-color: #EF4444;
                width: 12px;
            }

            .success_item {
                height: 100%;
                background-color: green;
                width: 12px;
            }

            .message {
                flex: 1;
            }
        }
    }
}

.table_container {
    background-color: white;
    padding: 32px 24px;
    border-radius: 25px 25px 25px 25px;
    height: 100%;
    overflow: auto;
}

/* {{{{{{{{{{==========  tablecontainer css  ============}}}}}}}}}} */
.table_container_con {
    & table {
        width: 100%;
    }

    & th {
        color: var(--Text, #333333);
        font-family: Lufga;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        border-bottom: 0.5px solid #D6D8DB;
        border-bottom: 1px solid #D6D8DB !important;
        text-align: left;
        padding: 8px 4px;
        background-color: white;
    }

    & td {
        color: var(--Text, #000000);
        font-family: Lufga;
        font-size: 16px;
        
        font-style: normal;
        line-height: normal;
        padding: 16px 4px;
        border-bottom: 0.1px solid #c2cdec;
    }
}

/* {{{{{{========= pagination css  ============}}}}}} */

.pagination_con {
    border-radius: 0px 0px 25px 25px;
    overflow: hidden;
}

/* {{{{{{========= NoDataPage css  ============}}}}}} */
.noData_page_con {
    .defaultParent {
        background-color: none;
        width: 100%;
        height: calc(100vh - 190px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contentBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .defaultImgBox {
        width: 75%;
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .defaultImgBox img {
        width: 100%;
        height: 100%;
    }

    .defaultDataBox {
        margin-top: 16px;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .defaultDataBox h3 {
        font-family: Georgia;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 1% 0 0 0;
    }

    .defaultDataBox p {
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 16px 0 24px 0;
        text-align: center;
    }
}


/* =================={{{{{{{{{{{{{{PopOver-Con css}}}}}}}}}}}}}}================== */
.p-overlaypanel{
    /* position: absolute; */
    background: none;
}
.p-overlaypanel-content {
    background: none;
    background-color: #DBF0E6;
    border-radius: 12px;
    padding: 0;
    opacity: 100% !important;
}

.p-overlaypanel-enter-done {
    background-color: none !important;
    border-radius: 16px;
    opacity: 100% !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
    display: none !important;
}

.p-button,
.card {
    background: none;
    color: #363F57;
    border: none;
    padding: 0;
}

.popoverListContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    border-radius: 10px;
    padding:8px 16px;
    align-items: flex-start;
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-align: center;
}
.EditPop{
    font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
/* text-align: left; */
color: #202224;
}
.EditPop1{
    font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
/* text-align: left; */
color: red;
}

.optionList {
    padding:8px 0;
    .danger{
        color: #D00000;
    }

    hr{
        color: #363F57;
    }
}
.optionList :hover{
    cursor: pointer;
}

.profileOptionList {
    width: 200px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #002356;
    color: white;
}
.profileOptionList :hover{
    cursor: pointer;
    /* background-color:#002356 ; */
    /* color: white; */
}
/* for primary button */
.primary-btn{
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: 'DM Sans';
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    background-color: #002356;
}

.danger-btn{
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: 'DM Sans';
    padding: 8px 24px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: #D00000;
}







