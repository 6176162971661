.p-dialog-content {
    padding: 30px;
    border-radius: 6px;
}


.p-accordion-content {
    padding: 0;
    border: none;
}

.p-accordion-header {
    border-radius: 8px;
}

.p-highlight a {
    background-color: #187C8B !important;
    color: white !important;
}

.p-accordion-header a {
    background-color: #DCE7EC;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border: none;
    border-radius: 8px;
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 31.32px;
    letter-spacing: -0.11428570002317429px;
    text-align: left;
    display: flex;
    flex-direction: row-reverse;

}

.p-icon {
    box-sizing: content-box;
    background: white;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid skyblue;
    color: black;
}

.p-accordion-tab-active,
.p-accordion-tab {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: ease-in-out all;
}


.p-accordion {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.p-accordion-content {
    background-color: #F5F6FA;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    border: none;
}


.p-accordion-content .p-accordion {
    gap: 0rem;
}

.p-accordion-content .p-accordion .p-accordion-content {
    border-radius: 0px;
    margin-top: 0px;
}

.p-accordion-content .p-accordion .p-accordion-content .p-accordion-tab {
    margin-top: 0px !important;
}


.p-accordion-content .p-accordion .p-accordion-tab {
    margin-bottom: 1.5px;
    border-radius: 0px;
}

.p-accordion-content .p-accordion .p-accordion-tab .p-accordion-header a {
    background: white;
    border-radius: 0px;
}

.p-accordion-content .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    background: white !important;
    color: black !important;
}

.p-accordion-content .p-accordion .p-accordion-tab,
.p-accordion-content .p-accordion .p-accordion-tab-active {
    gap: 0rem;
}


.p-accordion-content .p-accordion .p-accordion-tab-active .p-accordion-header-text {
    color: #187C8B;
}






.AccordingTab {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    margin-top: 1.5px;
}

.AccordionBAck .AccordionBAck .AccordingTab {
    margin-top: 0px;
}

.TabHeading {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #252B42;
    display: flex;
    align-items: center;
}

.AccordionTabheader {
    background-color: none;
}

.AccordionTabheader .AccordionTab.active {
    background-color: rgb(0, 128, 170);
} 