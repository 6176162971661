.ViewButton {
  border: none;
  border-radius: 12px;
  background-color: #c5dee2;
  color: black;
  padding: 8px 20px;
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.27px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  display: flex;
  align-items: center;
}
.IconCustom {
  font-size: 10px;
}
.AddDocumnentButton {
  background-color: #187c8b;
  font-family: Lufga;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.49px;
  text-align: center;
  border: none;
  padding: 10px 24px;
  border-radius: 12px;
  color: white;
}
.AssingedTex {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #222222;
  font-family: Lufga;
}
.AddPocText {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #187c8b;
  font-family: Lufga;
}

.searchbar {
  color: black;
  padding-left: 10px !important;
}

.ProfileImages{
  width:60px !important;
  border-radius: 50% 50%;
  height:60px;

}
