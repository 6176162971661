.s_card_container{
    border: 0.93px solid #E2E2E2;
    border-radius: 8px;
    padding: 44.79px 0px 30.79px 0px;
    min-height: 600px;
    height: max-content;
    /* margin: 0.5em; */
}
.header {
    font-size: 30px;
    font-weight: 600;
    color: black;
    text-align: center;
}
.discription {
    font-size: 24px;
    color: #187c8B;
    text-align: center;
}