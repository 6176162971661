
.card_wrapper a{
    text-decoration: none;
    color:unset;
}   

.card_wrapper a{
        text-decoration: none;
        color:unset;
    }

.card_wrapper {
    padding: 0.8rem;
    background: white;
    box-shadow: 6px 6px 54px 0px #0000000D;
    border-radius: 5%;



    .card_body div {
        display: flex;
        text-align: left;
    }

    .card_body {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .card_upper {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }

    .upper_left {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }


    .left_lower {
        font-family: Nunito Sans;
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 1px;
        text-align: left;

    }

    .upper_right {
        border-radius: 30%;
        height:40px;
        width: 40px;
    }

    .card_img_top{
        height: 100%;
        width: 100%;
    }

    .card_lower {
        display: flex;
        gap: 0.2rem;
        font-size: 1rem;
    }


}