.backicon{
    /* justify-content: center; */
    /* margin-bottom: 20px; */
    /* font-size: 2rem; */
    margin-bottom: 9px;
    cursor: pointer;
}

.backbtn{
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Lufga;
font-size: 24px;
font-weight: 600;
line-height: 31.32px;
text-align: left;
color: #040F2D;
/* padding-bottom: 30px; */
}