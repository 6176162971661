.main_container1 {
    /* background: red; */
    padding: 2rem 0 0 0;
}

.card_wrapper a{
    text-decoration: none;
    color:unset;
}    .card_wrapper a{
        text-decoration: none;
        color:unset;
    }

.card_wrapper {
    padding: 0.8rem;
    background: white;
    box-shadow: 6px 6px 54px 0px #0000000D;
    border-radius: 5%;



    .card_body div {
        display: flex;
        text-align: left;
    }

    .card_body {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .card_upper {
        /* display: flex; */
        justify-content: space-between;
        gap: 1rem;
    }

    .upper_left {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .left_upper {}

    .left_lower {
        font-family: Nunito Sans;
        font-size: 25px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 1px;
        text-align: left;

    }

    .upper_right {
        border-radius: 30%;
        height:40px;
        width: 40px;
    }

    .card_img_top{
        height: 100%;
        width: 100%;
    }

    .card_lower {
        /* display: flex; */
        gap: 0.2rem;
        font-size: 1rem;
    }
    


}
.DialogHeading{
    font-family: Lufga;
   
font-size: 25px;
font-weight: 500;
line-height: 42px;
letter-spacing: -0.11428570002317429px;
text-align: left;
color:#202224;

;
}
.p-dialog-content{
    padding: 10px;
}
.SponsorText{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #202224;
    
}
.NameText{
    font-family: Lufga;
    font-size: 20px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 1px;
    text-align: left; 
    margin-left: 10px;
    
    color: #202224;
}
.QrRounded{
    display: flex ;
    justify-content: center;
    align-items: center;
    margin-top: 20px ;
    margin-bottom: 20px ;
    width: 300px;
height: 300px;
background-color: #BAD8DC;
border-radius: 30%;

}
.ScanCodeimg{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }
    .Linkweb{
        font-family: Lufga;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0px;
text-align: left;
color: #000000;

    }


.IconViaText{
    font-family: Lufga;
font-size: 20px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
color: #187C8B;
margin: 10px 10px 0 0;

}


.card_wrapper a {
    text-decoration: none;
    color: unset;
}

.card {
    border: 1px solid #E8E8EA;
}

.card:hover {
    background: unset !important;
    color: unset;
}



.auction_card {

    width: 100%;

    .card_upper {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 19.86px 19.86px 0 0;
        height: 30vh;
        width: 100%;
        padding: 10px 10px 0 0;
        display: flex ;
        flex-direction: column;
        justify-content: space-between;
        .upper_v_1 {
            display: flex !important;
            justify-content: end !important;
            color: white;

            .upper_v_1_1 {
                width: 30px;
                height: 32px;
                background: white;
                border-radius: 8px;
                box-shadow: inset 1px 1px 2px gray;
                display: flex;
                justify-content: end;
                align-items: center;
                font-size: 26px;
                font-weight: 500;
                color: black;
                writing-mode: vertical-lr;

                .upper_v_1_1_1 {

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /* margin-left: 60%; */
                    padding-bottom: 2px;

                }
            }

        }

        .upper_v_2 {
            font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: #6A6A6A;
        }

    }

    .upper_v_2 {

      
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #6A6A6A;

        .upper_v_2_1{
            color:white;
            background: black;
            display: inline-block;
            padding:0.4rem;
            border-radius: 6.56px;
            font-weight: 500;
            font-family: 'Poppins';
        }
    
    }

    .card_lower {

        padding: 1.2rem;
        background: #ffffff;
        border-radius: 0 0 19.86px 19.86px;

        .lower_v_1 {
            font-weight: 600;
        }

        .lower_v_2 {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
           
        }
.Currenttext{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  color: #000000 ;   
}
.Auctiontext{
    font-family: Lufga;
font-size: 20px;
font-weight: 500;
line-height: 31px;
letter-spacing: -0.11428570002317429px;
text-align: left;

}
.Dollertext{
    font-family: Lufga;
font-size: 20px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: #187C8B;
}
        .lower_v_3 {
            width: 40%;
            padding: 5px 0;
            border-radius: 7.99px;
            background: #187C8B;
            color: white;
            border: none;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .lower_v_Buy {
            width: 40%;
            padding: 5px 0;
            border-radius: 7.99px;
            /* background: #187C8B; */
            border: 1px solid black;
            color: rgb(0, 0, 0);
            
            font-size: 12px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
        }
.lower_v_Surro{
    color: #187C8B;
    font-family: Poppins;
font-size: 12px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
/* margin-left: 10px; */
margin: 10px ;

}
    }


    .footer .lufga_16px {
        line-height: 1.5rem;
    }
    
    .middle_lower {
        display: flex;
        justify-content: center;
        
    }
    
    .middle_lower button {
        background: white;
        border-radius: 25px;
        border: none;
        color: #187c8B;
        font-size: 1rem;
        padding:  10px 50px;
        box-shadow: 0px 3px 14px 0px rgba(77, 76, 76, 0.25) !important;
        font-weight: 600;
    }

}