.p-dialog-content {
    border-radius: 6px;
    padding: 10px 40px;
  }
  .HeaderDialog {
    width: 100%;
    background-color: #f5f6fa;
    padding: 20px;
  }
  .PrifileHeding {
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    padding-left: 20px;
  }
  .VerfiedButton1 {
    font-family: Lufga;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.22500000894069672px;
    text-align: left;
    background-color: #009252;
    color: white;
    padding: 8px 24px;
    border-radius: 71px;
    border: none;
  }
  .Textname {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: black;
  }
  
  .input_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    /* width: 100%; */
    width: 22vw;
    padding: 7px;
    margin-bottom: 1rem;
    border: 0.1px solid #e9e9f0;
    border-radius: 5px;
  }

  .input_text_dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    width: 100%;
    /* width: 22vw; */
    padding: 7px;
    margin-bottom: 1rem;
    border: 0.1px solid #e9e9f0;
    border-radius: 5px;
  }

  .arr{
    display: flex;
    padding: 10px 0px;
    align-items: center;
    margin-top: 0.2rem;
    gap: 0.8rem;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .arr_list{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #358C99;
    padding: 2px 14px;
    background-color: #DFE9EB;
    border-radius: 9px;
  }
  
  .cancelBtn {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: #187c8b;
    border-radius: 12px;
    border: none;
  }
  
  .ProfileText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
  }
  .ViewResumeButton {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #187c8b;
    color: white;
  }
  .SaveProfileButton {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #f5f6fa;
    color: #202224;
  }
  .LabelHeading {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;
  }
  .LoctionText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
  }
  .CurrentText {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
  }

  .Planname{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 1rem; */
    margin: 1rem 0px;
    /* width: 100%; */
    /* width: 80vw; */
  }
  .CurrentText1 {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
  }
  .InputCustom {
    background-color: #e8e8ea;
  }
  
  .ProfilePic {
    /* height: 100%; */
    width: 76%;
  }
  
  .ProfilePic img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  
  .CancelButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: #101828;
    border-radius: 12px;
    border: none;
  }
  
  .SaveButton {
    width: 193px;
    height: 65px;
    color: white;
    border-radius: 12px;
    border: none;
    background-color: #187c8b;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }
  