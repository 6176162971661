.theme_button{
    background: #187c8b;
    border:none;
    outline: none;
    padding: 0.4rem 1.5rem;
    border-radius: 8px;
    color:white;
    font-family: Lufga;
}


.cancelButton {
    padding: 0.4rem 1.5rem;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: #101828;
    border-radius: 12px;
    border: none;
}

.saveButton {
    padding: 0.4rem 1.5rem;
    color: white;
    border-radius: 12px;
    border: none;
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}

.wsnb{
    white-space: nowrap;
}

.no_style_button{
    background: transparent;
    border: none;
}