.MyProfile {
    background-color: white;
    padding: 30px;
    margin: 20px 0;
    border-radius: 25px;
}

.bannersize {
    background-color: #F5F6FA;
    border-radius: 8px;
    height: 220px;
    display: flex;
    justify-content: space-between;
}

.notification_on_off_btn {
    width: fit-content;
    font-family: DM Sans;
    border-radius: 6px;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    text-align: left;
}

.notification_on_btn {
    background-color: #00b69b48;
    color: #00B69B;


}

.notification_off_btn {
    background-color: #F4CFCC;
    color: #EF3826;
}

.ImgesandIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px;
}

.imgegeCrossIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    color: red;
    z-index: 99;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.BnnerFirstText {
    font-family: Lufga;
    color: #187C8B;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.012em;
    text-align: left;


}

.BnnersecondtText {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.VerfiedButton {

    background-color: white;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    color: black;
}

.profile_img_con {
    position: relative;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}


.verfiedText {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
}



.ChlidButton {
    position: absolute;
    bottom: 45px;
    right: 50px;
}

.Profilebackground {
    background-image: url('/public/images/Profilebackground.png');
    width: 171px;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;


}

.ProfileImg {
    width: 335px;
    height: 235px;
    border-radius: 100%;
}

.ProfileImg1 {
    width: 171px;
    height: 171px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
}

.inputIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    background: #00000063;

}

.CancelButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: rgba(24, 124, 139, 1);
    border-radius: 12px;
    border: none;
}

.SaveButton {
    width: 150px;
    height: 50px;
    color: white;
    border-radius: 12px;
    border: none;
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}

.Images_Border {
    border-style: dashed;
    color: #187C8B;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;

}

.center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.UploadName {
    text-align: center;
    color: #000000;
    font-family: Lufga;
    font-size: 1rem;
    font-weight: 400;


}

.ChoosImages {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.012em;
    text-align: center;
    color: black;
}

.verfideText {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.012em;
    text-align: center;
    color: black;
}

.imagesCustom {
    font-size: 3rem;
    color: #187C8B;
    text-align: center;
    align-items: center;
    cursor: pointer;


}

.ImgesCustom {
    width: 100px;
    height: 100px;
    display: flex;
    gap: 10px;
    object-fit: cover;
    position: relative;
}