.MyProfile {
    background-color: white;
    padding: 30px;
    margin: 20px 0;
    border-radius: 25px;
}

.bannersize {
    background: rgba(245, 246, 250, 1);
    border-radius: 8px;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.match_making_heading {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #187C8B;
}

.resume_image_con {
    position: relative;

    .imgegeCrossIcon2 {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: white;
        color: red;
        z-index: 99;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
    }
}


.ImgesandIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px;
}

.imgegeCrossIcon {
    position: absolute;
    left: 40px;

    cursor: pointer;
}

.BnnerFirstText {
    font-family: Lufga;
    font-size: 1.85rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #187C8B;
}

.BnnersecondtText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.VerfiedButton {

    background: white;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    color: black;
    box-shadow: 2px 2px 3px #727272, -1px -1px 2px #727272;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}


.verfiedText {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
}



/* .ChlidButton {
    position: absolute;
    bottom: 45px;
    right: 50px;
} */

.Profilebackground {
    background-image: url('/public/images/Profilebackground.png');
    width: 171px;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;


}

.ProfileImg {
    width: 235px;
    height: 235px;
    border-radius: 100%;
}

.ProfileImg1 {
    width: 171px;
    height: 171px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
}

.inputIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    background: #00000063;

}

.CancelButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: rgba(24, 124, 139, 1);
    border-radius: 12px;
    border: none;
}

.SaveButton {
    width: 150px;
    height: 50px;
    color: white;
    border-radius: 12px;
    border: none;
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}

.Images_Border {
    border-style: dashed;
    color: #187C8B;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;

}

.center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.UploadName {
    text-align: center;
    color: #000000;
    font-family: Lufga;
    font-size: 1rem;
    font-weight: 400;


}

.ChoosImages {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.012em;
    text-align: center;
    color: black;
}

.verfideText {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.012em;
    text-align: center;
    color: black;
}

.VerifyResumeButton {
    background: white;
    padding: 12px 24px;
    border-radius: 71px;
    border: none;
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.30000001192092896px;
    color: #202224;
}

.UpadateResumeButton {
    background: transparent;
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.27px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    color: #187C8B;
}

.imagesCustom {
    font-size: 3rem;
    color: #187C8B;
    text-align: center;
    align-items: center;
    cursor: pointer;


}

.ImgesCustom {
    width: 100px;
    height: 100px;
    display: flex;
    gap: 10px;
    object-fit: cover;
    position: relative;
}

.ctcBtn {
    border: none;
    background: rgba(24, 124, 139, 1);
    color: white;
    border-radius: 6px;
    padding: 10px 0;
    font-size: 1.1rem;
}

.v_download_btn {
    background: rgba(245, 246, 250, 1);
    border: 1px solid rgba(245, 246, 250, 1);
    color: rgba(32, 34, 36, 1);
    box-shadow: 0px 0px 3px -1px rgba(32, 34, 36, 1);
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}


.verified {
    background: #1BA345 !important;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 1100px) {
    .ProfileImg1 {
        width: 150px;
        height: 150px;
    }

    .ProfileImg {
        width: 180px;
        height: 180px;
    }

    /* .ChlidButton {
        bottom: 40px;
        right: 30px;
    } */

    .VerfiedButton {
        border-radius: 60px;
        padding: 8px 15px;
        color: black;
        font-size: 12px;
    }

    .verfiedText {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
    }

    .BnnerFirstText {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 22px;
    }

    .BnnersecondtText {
        font-size: 18px;
        line-height: 20px;
    }

    .VerifyResumeButton {
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 400;
    }

    .UpadateResumeButton {
        font-size: 12px;
        line-height: 16px;
    }

    .LabelHeading {
        font-size: 17px;

    }
}

@media screen and (max-width: 750px) {
    .LabelHeading {
        font-size: 16px;

    }
}

.basicProfile_con {
    font-family: Lufga;

    .arrow_icon {
        background-color: #187C8B1A;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        color: #187c8b4a;
        align-items: center;
        font-size: 25px;
        justify-content: center;
        cursor: pointer;
    }

    .active_arrow_icon {
        color: #187C8B;
    }
}

.addChild_btn {
    all: unset;
    background-color: #F5F6FA;
    font-family: Lufga;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    line-height: 18.27px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    border-radius: 70px;
    gap: 10px;
    justify-content: center;
}

.complete_text {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 47.58px;
    text-align: left;

}

.second_heading {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #187C8B;
}

.tabs_container {
    display: flex;
    margin-top: 30px;

    background-color: #F5F6FA;
}

.tab_item {
    font-size: 25px;
    width: 100%;
    border-radius: 9.6px;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    cursor: pointer;

}

.active_tab {
    background-color: #187C8B !important;
    color: white;

}

.navLink {
    text-decoration: none;
    padding: 10px 20px;
    color: #333;
    position: relative;
    color: black;
    /* background: #fff; */
    gap: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 5px 10px 5px 10px;
    cursor: pointer;
}


.navLink::after {
    color: #fff;
    z-index: -1;
}

.active {
    z-index: 1;
    color: #fff;
    background-color: #187C8B;
}

.active::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    border-radius: 0px 10px 5px 0;
    left: 10px;
    background-color: #187C8B;
    transform: skew(10deg);
}