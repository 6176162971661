.HeaderDialog {
  width: 100%;
  background-color: #F5F6FA;
  padding: 20px 30px;

}
.arrow_icon{
  background-color: #187C8B1A;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  color: #187c8b4a;
  align-items: center;
  font-size: 25px;
  justify-content: center;
  cursor: pointer;
}
.active_arrow_icon{
  color:#187C8B;
}
.ProfilePic{
  width: 187px;
  height: 187px;
  border-radius: 100%;
  overflow: hidden;
}

.subHeading{
  color : rgba(16, 24, 40, 1);
  font-size: 16px;
  font-family: lufga;
  font-weight: bold;
}
.second_heading {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #187C8B;
}
.PrifileHeding {
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  padding-left: 20px;
}
.Textname {
  font-family: Lufga;
  font-size: 27.7px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: black;

}
.Textname1 {
  font-family: Lufga;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: black;

}
.VerfiedButton1 {
  font-family: Lufga;
  font-size: 11.55px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.26px;
  text-align: left;
  background-color: #009252;
  color: white;
  padding: 9.24px 27.73px;
  border-radius: 71px;
  border: none;
}
.ProfileText {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justify;
  color: #6A6A6A;
}
.LoctionText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #202224;
}

.styleIngrid{
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  gap: 1%;
}

.VerifyResumeButton {
  background: white;
  padding: 12px 24px;
  border-radius: 71px;
  border: none;
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: #202224;
}
.save_profile{
    width: Hug (151.41px)px;
height: Hug (48.7px)px;
padding: 13.85px 27.7px 13.85px 27.7px;
gap: 9.23px;
border-radius: 81.96px ;
/* opacity:1; */
background: #F5F6FA;
border: 1px solid #F5F6FA;
}
.save_profile:hover{
  background: #187C8B;
  color: #ffffff;
}
.clear_profile{
  width: Hug (151.41px)px;
height: Hug (48.7px)px;
padding: 13.85px 27.7px 13.85px 27.7px;
gap: 9.23px;
border-radius: 81.96px ;
/* opacity:1; */
background: #dadde6;
border: 1px solid #F5F6FA;
}
.clear_profile:hover{
background: #187C8B;
color: #ffffff;
}
.view_profile{
    width: Hug (164.41px)px;
height: Hug (48.7px)px;
padding: 13.85px 27.7px 13.85px 27.7px;
gap: 9.23px;
/* border-radius: 81.96px ; */
/* opacity: 1; */
background: #187C8B;
border: 1px solid #F5F6FA;
border-radius: 8px;
color: #ffffff;
}
.Search_con {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 25px;
}

.CreditText {
  font-family: Lufga;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #040f2d;
  display: flex;
  align-items: center;
}

.ExportButton {
  background-color: #187c8b;
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.FormCustom {
  width: 100%;
  /* height: 576px; */
  background-color: #f5f6fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}
.LabelText {
  font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.012em;
text-align: left;
  color: #101828;
}
.SearchButton {
  background-color: #187c8b;
  padding: 12px 24px;
  border: none;
  border-radius: 71px;
  color: white;
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
}

.auction_card {
  width: 100%;
}
.card_upper_mask{
  position: absolute;
  top:0px;
  height:100%;
  width: 100%;
  z-index:100;
  filter:blur(3px);
  background-position: center;
  background-repeat: no-repeat;
}

.card_upper_image{
  position: absolute;
  top:0px;
  height:100%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index:100;
}
.card_upper {
   position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 19.86px 19.86px 0 0;
  height: 220px;

  width: 100%;
  padding: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  
  .upper_v_1 {
    display: flex !important;
    justify-content: end !important;
    color: white;
    z-index: 100;

    .upper_v_1_1 {
      width: 30px;
      height: 32px;
      background: white;
      border-radius: 8px;
      box-shadow: inset 1px 1px 2px gray;
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 30px;
      font-weight: 500;
      color: black;
      writing-mode: vertical-lr;

      .upper_v_1_1_1 {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-left: 60%; */
        padding-bottom: 2px;
      }
    }
  }

  .upper_v_2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #6a6a6a;
    z-index: 100;
  }
}

.card_lower {
  padding: 1.2rem;
  background: #ffffff;
  border-radius: 0 0 19.86px 19.86px;
}

.cardAgeText {
  font-weight: 600;
  color: #000000;
  text-decoration: unset;
  outline: unset;
}

.lower_v_2 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  text-decoration: none !important;
  margin: 15px 0;
}
.Currenttext {
  font-family: Lufga;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.Auctiontext {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.11428570002317429px;
  text-align: left;
}
.Dollertext {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #187c8b;
}
.lower_v_3 {
  width: 40%;
  padding: 5px 0;
  border-radius: 7.99px;
  background: #187c8b;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
}
.lower_v_Buy {
  width: 40%;
  padding: 5px 0;
  border-radius: 7.99px;
  /* background: #187C8B; */
  border: 1px solid black;
  color: rgb(0, 0, 0);

  display: flex;
  justify-content: center;
}
.lower_v_Surro {
  color: #187c8b;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-left: 10px; */
  margin: 10px;
}

.footer .lufga_16px {
  line-height: 1.7rem;
}

.middle_lower {
  display: flex;
  justify-content: center;
}

.middle_lower button {
  background: white;
  border-radius: 25px;
  border: none;
  color: #187c8b;
  font-size: 1rem;
  padding: 10px 50px;
  box-shadow: 0px 3px 14px 0px rgba(77, 76, 76, 0.25) !important;
  font-weight: 600;
}
.card_wrapper a {
  text-decoration: none;
  color: unset;
}

.card {
  border: 1px solid #e8e8ea;
  border-radius: 22px !important;
}

.card:hover {
  background: unset !important;
  color: unset;
}

.cardContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.maincardContainer {
  height: 100%;
  overflow: hidden;
  border-radius: 19.86px 19.86px;
  box-shadow: 2px 2px 4px lightgray, -1px -1px 3px lightgray;
  display: flex;
  flex-direction: column;
}

.upper_v_2 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6a6a6a;

  .upper_v_2_1 {
    color: white;
    background: black;
    display: inline-block;
    padding: 0.4rem;
    border-radius: 6.56px;
    font-weight: 500;
    font-family: "Poppins";
  }
}
.card_lower {
  padding: 1.3rem;
  background: #ffffff;
  border-radius: 0 0 19.86px 19.86px;
  /* height:auto; */
  flex-grow: 1;

  .lower_v_1 {
    font-weight: 600;
  }

  .lower_v_1:hover {
    text-decoration: underline;
  }

  .lower_v_2 {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }

  .Currenttext {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Auctiontext {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.11428570002317429px;
    text-align: left;
  }

  .Dollertext {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #187c8b;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lower_v_3 {
    width: 100%;
    border-radius: 7.99px;
    background: #187c8b;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
  }

  .lower_v_Buy {
    width: 100%;
    border-radius: 7.99px;
    /* background: #187C8B; */
    border: 1px solid #187c8b;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
  }

  .lower_v_Surro {
    color: #187c8b;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    /* margin-left: 10px; */
    margin: 10px;
  }
}

.sBtn {
  width: fit-content;
  border-radius: 7px;
  background: rgba(24, 124, 139, 0.06);
  padding: 4.37px 10.93px 4.37px 10.93px;
  color: rgba(24, 124, 139, 1);
  font-weight: 500;
  line-height: 21.85px;
  font-family: "Poppins";
}
.lower_v_3 {
  width: 100%;
  border-radius: 7.99px;
  background: #187c8b;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
}
.lower_v_Buy {
  width: 100%;
  border-radius: 7.99px;
  /* background: #187C8B; */
  border: 1px solid #187c8b;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
}

.MyProfile {
  background-color: white;
  padding: 30px;
  margin: 20px 0;
  border-radius: 25px;
}

.bannersize {
  background-color: #f5f6fa;
  border-radius: 8px;
  height: 220px;
  padding: 8px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.notification_on_off_btn {
  width: fit-content;
  font-family: DM Sans;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  text-align: left;
}

.notification_on_btn {
  background-color: #00b69b48;
  color: #00b69b;
}

.notification_off_btn {
  background-color: #f4cfcc;
  color: #ef3826;
}

.ImgesandIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 20px;
}

.imgegeCrossIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  color: red;
  z-index: 99;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.BnnerFirstText {
  font-family: Lufga;
  color: #187c8b;
  font-size: 32px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
}

.BnnersecondtText {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #000000;
}
.Savedpost_icon{
  background-color: white;
  padding: 20px;
  border-radius: 16px;
}

.VerfiedButton {
  background-color: white;
  border: none;
  border-radius: 71px;
  padding: 12px 24px;
  color: black;
}

.profile_img_con {
  position: relative;
}
.CreditText {
  font-family: Lufga;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #040F2D;
}

.LabelHeading {
  font-family: Lufga;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #101828;
}

.verfiedText {
  font-family: Lufga;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
}

.ChlidButton {
  position: absolute;
  bottom: 45px;
  right: 50px;
}

.Profilebackground {
  background-image: url("/public/images/Profilebackground.png");
  width: 171px;
  height: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.ProfileImg {
  width: 235px;
  height: 235px;
  border-radius: 100%;
}

.ProfileImg1 {
  width: 171px;
  height: 171px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.inputIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  background: #00000063;
}

.CancelButton {
  font-family: Lufga;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background-color: white;
  color: rgba(24, 124, 139, 1);
  border-radius: 12px;
  border: none;
}

.SaveButton {
  width: 150px;
  height: 50px;
  color: white;
  border-radius: 12px;
  border: none;
  background-color: #187c8b;
  font-family: Lufga;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.Images_Border {
  border-style: dashed;
  color: #187c8b;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.center_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.UploadName {
  text-align: center;
  color: #000000;
  font-family: Lufga;
  font-size: 1rem;
  font-weight: 400;
}

.ChoosImages {
  font-family: Lufga;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.012em;
  text-align: center;
  color: black;
}

.verfideText {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.012em;
  text-align: center;
  color: black;
}

.imagesCustom {
  font-size: 3rem;
  color: #187c8b;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.ImgesCustom {
  width: 100px;
  height: 100px;
  display: flex;
  gap: 10px;
  object-fit: cover;
  position: relative;
}
@media (max-width: 1370px) {
  .cardContainer {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 1300px) {
  .styleIngrid{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
  }
}
