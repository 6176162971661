.t_heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}
.MainHeading {
  font-family: Lufga;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.32px;
  text-align: left;
  color: #040f2d;
  padding-bottom: 30px;
}

.LabelText {
  font-family: Lufga;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #101828;
}
.CencelButton{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    background-color: transparent;
    border: none;
    
}
.SubmitButton{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: #187C8B;
    width: 150px;
    height: 55px;
    border: none;
    border-radius: 12px;
    color: white;
    
}