.Search_con{
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 25px;
}

    .CreditText {
        font-family: Lufga;
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: #040F2D;
    }

    .ExportButton {
        background-color: #187C8B;
        font-family: Lufga;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 10px;

    }
    .FormCustom{
        width: 100%;
        /* height: 576px; */
        background-color: #F5F6FA;
        padding: 40px;
        border-radius: 8px;
    }
    .LabelText{
        font-family: Lufga;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.012em;
text-align: left;
color: #101828;
    }
    .SearchButton{
    background-color: #187C8B;
    padding: 12px 24px ;
    border: none;
    border-radius: 71px;
    color: white;
    font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: left;

    }

    .card {
        border: none;
        /* width: 100% !important; */
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 14.02px 14.02px 21.03px 14.02px;
        border-radius: 14.02px;
        gap: 21.03px;
        box-shadow: 0px 3.5054876804351807px 14.021950721740723px 0px rgba(158, 158, 158, 0.25);
    }
    
    .cardImgTop {
        border-radius: 16px;
    }
    
    .bodyOfCard {
        display: flex;
    }
    
    .cardBody {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        padding: 0;
    }
    
    .card:hover {
        background: rgba(24, 124, 139, 1) !important;
        cursor: pointer;
        color: white !important;
        .lufga_18px{
            color: white;
                }

    }
    
    .all_stories {
        padding: 3rem;
    }
    
    .all_stories a {
        display: inline-block;
        text-decoration: none;
        width: 100%;
        height: 100%;
    }
    
    .cardBody .lufga_18px {
        font-size: 1rem;
    }
    .lufga_18px {
        font-family: 'Lufga', sans-serif;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 2.25rem;
        letter-spacing: 0em;
        text-align: left;
        color: black;
    }

