.toggle_of_sub_navlinks{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 10%;
}
.toggle_of_sub_navlinks1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 30%;
}

.sub_navlinks{
    display: flex;
    /* justify-content: flex-end; */
    justify-content: center;
}