.MyProfile {
    background-color: white;
    padding: 30px;
    margin: 20px 0;
    border-radius: 25px;
}

.bannersize {
    background-color: #F5F6FA;
    border-radius: 8px;
    height: 220px;
    display: flex;
    justify-content: space-between;
}

.BnnerFirstText {
    font-family: Lufga;
    font-size: 32px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #187C8B;
}

.BnnersecondtText {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.VerfiedButton {

    background-color: white;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    color: black;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}

.InputCustom {
    background-color: #E8E8EA;
}

.verfiedText {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
}

.ParentButton {
    position: relative;
}

.ChlidButton {
    position: absolute;
    bottom: 5%;
    right: 20%;
}

.Profilebackground {
    background-image: url('/public/images/Profilebackground.png');
    width: 171px;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;


}

.CancelButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background-color: white;
    color: #101828;
    border-radius: 12px;
    border: none;
}

.SaveButton {
    width: 193px;
    height: 65px;
    color: white;
    border-radius: 12px;
    border: none;
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}

.NotificationText {
    background-color: #C4E8E3;
    color: #00B69B;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    text-align: left;

}

.NotificationText1 {
    background-color: #F4CFCC;
    color: #EF3826;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    text-align: left;

}


.Verified_con {}

.verfideText {
    font-family: Lufga;
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: black;



}

.BackgroundText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;
}

.LabelText {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}

.CencelButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    background-color: transparent;
    border: none;

}

.SubmitButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: #187C8B;
    width: 150px;
    height: 55px;
    border: none;
    border-radius: 12px;
    color: white;

}



.delete_button {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: transparent;
    border: none;
    color: #de3e44;
}

.Edit_button {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: #187C8B;
    width: 150px;
    height: 55px;
    border: none;
    border-radius: 25px;
    color: white;

}

.headerButtonGroup button {
    border-radius: 8px;
    padding: 0.75rem 1rem;
    font-family: Lufga;
    font-size: 1rem;
    font-weight: 500;
    line-height: 15.66px;
    text-align: center;
    border: none;
}

.addAppointmentButton {
    background: #187C8B;
    color:white;
    display: flex;
    align-items: center;
    gap:0.4rem;
}

.appointmentListButton {
    background: white;
    color:black;
    display: flex;
    align-items: center;
    gap:0.4rem;
    box-shadow: inset 1px 1px 2px rgb(216, 215, 215);
}

.viewReportsButton{
    background: #c9dee4;
    color:#187c8b;
}