.searchbar {
    color: black;
    padding-left: 10px !important;
}
.ViewButton {
    border: none;
    border-radius: 12px;
    background-color: #c5dee2;
    color: black;
    padding: 8px 20px;
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.27px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    display: flex;
    align-items: center;
  }