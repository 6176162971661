.p-dropdown-trigger{
    svg{
        color: #000000;
    }
}
.rbc-event {
  background: #dbf0e6 !important;
  color: #187C8B !important;
  /* margin-left: 10px !important; */
  /* margin-right: 10px !important; */
  /* width: 7rem !important; */
}

.calendar_div {
  cursor: pointer;
}
.btn-div {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 5px;
}

.editUserBottom .saveChanges_Css {
  background-color: black !important;
  color: white !important;
}
.editUserParent {
  padding: 20px 72px !important;
}
.editUserBottom {
  gap: 0px !important;
}

.btns {
  padding: 5px;
  border: none;
  outline: none;
  padding: 8px 15px;
  background: #ccd3dd !important;
  cursor: pointer;
}

.monthly {
  border-radius: 12px 0px 0px 12px !important;
}

.daily {
  border-radius: 0px 12px 12px 0px !important;
}
.active_btn {
  background-color: #187C8B !important;
  color: white;
}
.plus_on_hover {

}
.p-placeholder, .selectTree .p-dropdown-trigger, .p-inputtext {
  color: white;
  font-family: "DM Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 2px !important;
}
.AddApointment{
  background-color: #187C8B;
  border-radius: 12px;
  border: none;
  padding: 16px 24px;
  color: white;
  font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23.49px;
text-align: center;

}

.doctorslist{
  background: white;
  border-radius: 12px;
  border: none;
  padding: 16px 24px;
  box-shadow: 0px 22.72269630432129px 39.76472091674805px 0px #F5F5F5;
  font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23.49px;
text-align: center;

}
