.btn-Primary {
  background: #187C8B;
  border: 1px solid #187C8B;
  color: white;
  font-weight: 500;
  font-size: 1em;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: Lufga;
}

.btn-Secondary {
  background: none;
  border: none;
  color: #187C8B;
  font-weight: 500;
  font-size: 1em;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Lufga;
}

.btn-Danger {
  background: red;
  border: 1px solid red;
  color: white;
  font-weight: 500;
  font-size: 1em;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Lufga;
}

.btn-Borderd {
  background: none;
  border: 1px solid black;
  color: black;
  font-weight: 500;
  font-size: 1em;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Lufga;
}


.label{
  font-family: 'Lufga' !important;
}

.p-dialog-content {
  border: 1px solid #187C8B;
  border-radius: 10px !important;
}