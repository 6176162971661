.main_container{
    height: 100vh;
    padding: 1rem;
    position: relative;
}

.image_container{
    position: relative;
}

.logo{
    position: absolute;
    top: 2rem;
    left: 2rem;
}


.image_container .img_main{
    height: 100%;
    width: 100%;
}

.footer{
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%,0);

}

