 
  
  .CreatePost{
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    margin-top: 20px;
  }
  .MainHeading{
    font-family: Lufga;
font-size: 24px;
font-weight: 600;
line-height: 31.32px;
text-align: left;
color: #040F2D;
padding-bottom: 30px;
  }
  .Images_Border {
    border-style: dashed;
    color: #187C8B;
    width: 100%;
    height: 20%;
    
}
.InputCustom{
    /* background-color: #E8E8EA; */
}
.LabelHeading{
    font-family: Lufga;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.012em;
text-align: left;
color: #101828;

}
.center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.UploadName {
    text-align: center;
    color: #000000;
    font-family: Lufga;
font-size: 1rem;
font-weight: 400;
padding-bottom: 10px;


}
  .imagesCustom{
   font-size: 3rem;
    color: #187C8B;
    text-align: center;
    align-items: center;
    cursor: pointer;


  }
  .cancelButton{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23.49px;
text-align: left;
color: #187C8B;
background-color: white;
border: none;
}
.SaveButton{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23.49px;
text-align: center;
background-color: #187C8B;
padding: 10px 24px;
color: white;
border: none;
border-radius: 12px;
}