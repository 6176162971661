.slider-section {
  position: relative;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
  button:disabled {
    display: none;
  }
 
  .p-carousel-items-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

  .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    left: -30px;
    z-index: 1;
    background-color: white;
    color: #2B2B2B;
    border-radius: 50%;
    transition: all .2s ease;
    width: 45px;
    height: 45px;
    padding: 0px;
    margin: 0 5px;
    box-shadow: 0px 4px 10px 0px rgb(150, 148, 148);
  }
  .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: -30px;
    z-index: 1;
    background-color: white;
    color: #2B2B2B;
    border-radius: 50%;
    transition: all .2s ease;
    width: 45px;
    height: 45px;
    padding: 0px;
    margin: 0px 5px;
    box-shadow: 0px 4px 10px 0px rgb(150, 148, 148);
  }
  .p-carousel .p-carousel-content .p-carousel-prev:hover{
    background-color:#7A1EA3 ;
    color: #ffffff !important;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:hover{
    padding-right: 6px;
    transition: all .5s ease;

  }
  .p-carousel .p-carousel-content .p-carousel-next:hover{
    color: #ffffff !important;
    background-color:#7A1EA3 ;}
   .p-carousel .p-carousel-content .p-carousel-next:hover{
    color: #ffffff !important;
    background-color:#7A1EA3 ;
    padding-left: 6px;
    transition: all .5s ease;
  }
  .slider-one-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer;
    gap: 8px;
   
    position: relative; 
    .simple-paragraph {
      color: #2B2B2B !important;
      font-size: 16px;
      text-align: center;
    
      font-weight: 500;
    }
  }
  
  .circle-image {
    position: relative; 
    overflow: hidden; 
    border-radius: 50%;
    height: 110px;
    width: 110px;
  }
  
  .circle-image img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s; 
  }
  .circle-image .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color:rgba(0, 0, 0, 0.6); ;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: transform 0.4s;
  }
  
  .slider-one-card:hover .circle-image img {
    transform: scale(1.13);
  }
  
 
  
  .slider-one-card:hover {
  .simple-paragraph {
    color: #7A1EA3;
   
  }
}
.slider-one-card:hover .circle-image .overlay{
  opacity: 1;
}
  
 
  .category-slider-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 290px;
    margin: 0 auto;
    background: white;
    height: 100%;
    /* gap: 24px; */
    box-shadow: 0px 2px 23px -14px #000;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    .rounded-image {
      
    
      img {
        border-radius: 12px;
      }
    }
    .gray-normal-btn {
      button {
        background: none;
        border: none;
        outline: none;
        color: #8f90a6;
        font-size: 19px;
        font-weight: 300;
        gap: 16px;
      }
    }
  }}