

.card_wrapper a {
    text-decoration: none;
    color: unset;
    
}

.card {
    border: 1px solid #E8E8EA;
}

.card:hover {
    background: unset !important;
    color: unset;
}



.auction_card {

    width: 100%;

    .card_upper {
        background-position: 50% 10%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 19.86px 19.86px 0 0;
        height: 220px;
        width: 100%;
        /* padding: 10px 10px 0 0; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .upper_v_1 {
            display: flex;
            justify-content: end;
            color: white;
            position: absolute;
            top: 10px;
            right: 10px;

            .upper_v_1_1 {
                /* width: 30px; */
                height: 30px;
                background: white;
                border-radius: 8px;
                box-shadow: inset 1px 1px 2px gray;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 500;
                color: black;
                /* writing-mode: vertical-lr; */

                .upper_v_1_1_1 {

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /* margin-left: 60%; */

                }
            }

        }

        .upper_v_2 {

            display: flex;
            justify-content: flex-start;
            position: absolute;
            bottom: 0px;
            left: 0px;

            .upper_v_2_1{
                color:white;
                background: black;
                display: inline-block;
                padding:0.4rem;
                border-radius: 6.56px;
                font-weight: 500;
                font-family: 'Poppins';
            }
        
        }

    }

    .card_lower {

        padding: 1.2rem;
        background: #ffffff;
        border-radius: 0 0 19.86px 19.86px;

        .lower_v_1 {
            font-weight: 600;
        }

        .lower_v_2 {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            height: 50px;
            overflow: scroll;
        }



        .lower_v_3 {
            width: 100%;
            padding: 10px 0;
            border-radius: 7.99px;
            background: #187C8B;
            color: white;
            border: none;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }

    }


}
.AuctionSpaceButton{
    padding: 12px 24px;
    border-radius: 71px;
    color: white;
    border: none;
    font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: left;
background-color: #187C8B;
}

.image_section{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}
.image_section img{
    width: 35%;
}
.content_section{
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
}
.lufga_20px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;

}
.btn-Primary{
    background: #187c8b !important;
    color: #ffffff !important;
    }