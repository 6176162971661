.AuctionSpaceButton{
    padding: 12px 24px;
    border-radius: 71px;
    color: white;
    border: none;
    font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: left;
background-color: #187C8B;
align-items: center;
cursor: pointer;
}