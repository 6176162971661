.container {
    width: 100%;
    min-height: 85vh;
    margin-top: 3vh;
    background: linear-gradient(130.63deg, rgba(255, 255, 255, 1.0) 1.55%, rgba(174, 242, 239, 0.2) 97.02%);
    font-family: Lufga;
    height: auto;
}

.mainHeading {
    font-family: Lufga;
    font-weight: 600;
    text-align: center;
    font-size: 2em;
    padding-top: 5%;
    color: #000000;
}