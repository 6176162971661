.AccordingTab {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 15px 10px;
    margin-top: 1.5px;
}

.AccordionBAck .AccordionBAck .AccordingTab {
    margin-top: 0px;
}

.TabHeading {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #252B42;
    display: flex;
    align-items: center;
}

.round{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 100%;
    box-shadow: 0px 2px 4px 0px #0000001A;
}

.AccordionTabheader {
    background-color: none;
}

.AccordionTabheader .AccordionTab.active {
    background-color: rgb(0, 128, 170);
    /* Change this to the desired color */
}

.PasswordBack {
    background-color: white;
    padding: 10px;
    /* border-radius: 12px; */
}

.LabelText {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}

.cancelButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.49px;
    text-align: left;
    color: #187C8B;
    background-color: white;
    border: none;
}

.SaveButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.49px;
    text-align: center;
    background-color: #187C8B;
    padding: 10px 24px;
    color: white;
    border: none;
    border-radius: 12px;
}


.t_heading{
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
}
.card {
    border: none;
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 14.02px 14.02px 21.03px 14.02px;
    border-radius: 14.02px;
    gap: 21.03px;
    box-shadow: 0px 3.5054876804351807px 14.021950721740723px 0px rgba(158, 158, 158, 0.25);
}

.card_img_top {
    border-radius: 16px;
}

.body_of_card {
    display: flex;
}


.card_body {
    padding: 0;
}
.lufga_18px{
    font-family: Lufga;
font-size: 18px;
font-weight: 400;
line-height: 23.49px;
text-align: left;
color: #000000;

}
.MainHeading{
    font-family: Lufga;
font-size: 24px;
font-weight: 600;
line-height: 31.32px;
text-align: left;
color: #040F2D;
padding-bottom: 30px;
  }
  .LabelText{
    font-family: Lufga;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.012em;
text-align: left;
color: #101828;

}

.CencelButton{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    background-color: transparent;
    border: none;
    
}
.SubmitButton{
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: #187C8B;
    width: 150px;
    height: 55px;
    border: none;
    border-radius: 12px;
    color: white;
    
}
.get_faqStyle{
    border: 1px solid #EBEBEB;
    border-radius: 16px;
    overflow: hidden;
}
.question_style{
    font-family: Lufga;
font-size: 16px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color: #187C8B;

}
.DeleteButton{
    width: Hug (127px)px;
height: Hug (40px)px;
padding: 8px 24px 8px 24px;
gap: 8px;
border-radius: 8px 0px 0px 0px;
opacity: 0px;
color: #D00000;
}