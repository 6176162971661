
.verfideText {
    font-family: Lufga;
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: black;



}

.BackgroundText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;
}

.LabelText {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}

.CencelButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #187C8B;
    background-color: transparent;
    border: none;

}

.SubmitButton {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    background: #187C8B;
    width: 150px;
    height: 55px;
    border: none;
    border-radius: 12px;
    color: white;

}

.Images_Border {
    border-style: dashed;
    color: #187C8B;
    border-radius: 8px;
}

.center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.UploadName {
    text-align: center;
    color: #000000;
    font-family: Lufga;
    font-size: 1rem;
    font-weight: 400;


}

.imagesCustom {
    font-size: 3rem;
    color: #187C8B;
    text-align: center;
    align-items: center;
    cursor: pointer;


}