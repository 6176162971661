.AccordingTab {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    margin-top: 1.5px;
}

.AccordionBAck .AccordionBAck .AccordingTab {
    margin-top: 0px;
}

.TabHeading {
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #252B42;
    display: flex;
    align-items: center;
}

.AccordionTabheader {
    background-color: none;
}

.AccordionTabheader .AccordionTab.active {
    background-color: rgb(0, 128, 170);
    /* Change this to the desired color */
}

.PasswordBack {
    background-color: white;
    padding: 10px;
    /* border-radius: 12px; */
}

.LabelText {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;

}

.cancelButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.49px;
    text-align: left;
    color: #187C8B;
    background-color: white;
    border: none;
}

.SaveButton {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.49px;
    text-align: center;
    background-color: #187C8B;
    padding: 10px 24px;
    color: white;
    border: none;
    border-radius: 12px;
}


.t_heading{
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
}

.round{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 100%;
    box-shadow: 0px 2px 4px 0px #0000001A;
}
.t_heading{
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
}