.three_dot_btn {
    all: unset;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-overlaypanel-content {
   
    background-color: white;
}