
.social_pages_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
  }
  
.cardContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}
.maincardContainer{
  height: 100%;
  overflow: hidden;
  border-radius: 19.86px 19.86px;
  box-shadow: 2px 2px 4px lightgray , -1px -1px 3px lightgray;
  display: flex;
  flex-direction: column;
}
  
  .social_pages_background {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 2rem;
    border-radius: 15px;
    /* height: 70vh; */
    /* margin-bottom: 8vh; */
    /* min-height: 68vh; */
    padding-left: none;
  }
  
  .nav {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
    /* margin-top: 1.9rem; */
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;
  }
  
  .navLink {
    text-decoration: none;
    padding: 10px 20px;
    color: #333;
    position: relative;
    color: black;
    background: #fff;
    gap: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 15px 0px 0 0;
  }
  
  .count{
    background-color: white;
    color: #187C8B;
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;justify-content: center;
    width: 25px;
  }
  
  .navLink::after{
    color: #fff;
    z-index: -1;
  }
  
  .active {
    z-index: 1;
    color: #fff;
    background-color: #187C8B;
  }
  
  .active::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    border-radius: 5px 10px 0 0;
    left: 10px;
    background-color: #187C8B; 
    transform: skew(10deg);
  }
  
  .searchbar{
    color:black;
    /* width: 30vw; */
    width:30%;
    height: 5vh;
    padding-left: 10px !important;
    /* margin-right: 3vw; */
  }
  
  .search{
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .subcription_main{
    display: flex;
    /* border: 10px solid green; */
    align-items: center;
    /* justify-content: space-between; */
    justify-content: space-between;
    /* min-width: 80%; */
    position: relative;
    border-radius: 10px;
    margin: 0px 1rem;
    padding: 2rem 3rem;
    box-shadow: 0px 0px 0.5px 0.5px rgba(0, 0, 0, 0.5);   
  }
  
  .main_left{
    display: flex;
    gap:2rem;
    align-items: center;
  }
  
  .subcription_plan{
    border: 10px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    
  }
  
  .subcription_plan_chart{
    border: 10px solid blue;
    display: flex;
    /* justify-content: space-between; */
    /* gap: 50px; */
    align-items: center;
    /* width: 70vw; */
    /* margin: 0px 5px; */
  }
  
  .plan{
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .txt{
    color: #187C8B;
  }
  
  .dot{
    color: #1BA345;
    margin-left: -0.5rem;
  }
  
  .plan_details{
    /* width: 50vw; */
    min-height: 15vh;
    display: flex;
    flex-direction: column;
  }
  
  .arr{
    display: flex;
    padding: 10px 0px;
    align-items: center;
    margin-top: 0.2rem;
    gap: 0.8rem;
    /* border: 2px solid red; */
    /* width: 55vw; */
    flex-wrap: wrap;
  }
  
  .arr_list{
    border: 1px solid cadetblue;
    padding: 2px 14px;
    background-color: #DFE9EB;
    border-radius: 9px;
  }
  
  .features_not_included{
    display: flex;
    padding: 10px 0px;
    align-items: center;
    margin-top: 0.2rem;
    gap: 0.8rem;
    /* border: 2px solid red; */
    /* width: 55vw; */
    flex-wrap: wrap;
  }
  .features_not_included_list{
  
    border: 1px solid #DC1919;
    background: #DC19191A;
    padding: 2px 14px;
    border-radius: 9px;
  }
  
  
  
  .price{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    margin-left: 15px;
    /* border: 2px solid salmon; */
  }
  
  .validity{
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    /* display: inline; */
    margin-top: 0.5rem;
    padding: 10px;
    gap:0.6rem;
    /* background-color: aqua; */
  }
  
  .dot_icon{
    position: absolute;
    top: 1%;
    right: 1%;
    font-size: 1.5rem;
  }





  

.card_wrapper a {
  text-decoration: none;
  color: unset;
  
}

.card {
  border: 1px solid #E8E8EA;
}

.card:hover {
  background: unset !important;
  color: unset;
}

.card_upper_mask{
  position: absolute;
  top:0px;
  height:100%;
  width: 100%;
  z-index:100;
  filter:blur(3px);
  background-position: center;
  background-repeat: no-repeat;
}

.card_upper_image{
  position: absolute;
  top:0px;
  height:100%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index:100;
}

.maincardContainer{
  height: 100%;
  overflow: hidden;
  border-radius: 19.86px 19.86px;
  box-shadow: 2px 2px 4px lightgray , -1px -1px 3px lightgray;
  display: flex;
  flex-direction: column;

  .card_upper {
      /* background-position: 50% 10%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 19.86px 19.86px 0 0;
      height: 220px;
      width: 100%;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative; */
      position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 19.86px 19.86px 0 0;
    height: 220px;
  
    width: 100%;
    padding: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

      .upper_v_1 {
          display: flex;
          justify-content: end;
          color: white;
          position: absolute;
          top: 10px;
          right: 10px;
    z-index: 100;


          .upper_v_1_1 {
              /* width: 30px; */
              height: 30px;
              background: white;
              border-radius: 8px;
              box-shadow: inset 1px 1px 2px gray;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;
              font-weight: 500;
              color: black;
              /* writing-mode: vertical-lr; */

              .upper_v_1_1_1 {

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  /* margin-left: 60%; */

              }
          }

      }

      .upper_v_2 {
        width: 100%;

          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0px;
          left: 0px;
    z-index: 100;


          .upper_v_2_1{
              color:white;
              font-size: 0.7rem;
              background: black;
              display: inline-block;
              padding:0.4rem;
              border-radius:0 6.56px 0 0;
              font-weight: 400;
              font-family: 'Poppins';
          }

          .upper_v_2_2{
            color:black;
            background: white;
            display: inline-block;
            padding:0.4rem;
            border-radius: 6.56px 0 0 0;
            font-weight: 500;
            font-family: 'Poppins';
        }
      
      }

  }

  .card_lower {

      padding: 1.2rem;
      background: #ffffff;
      border-radius: 0 0 19.86px 19.86px;
      box-shadow: 1px 1px 12px dimgray;

      .lower_v_1 {
          font-weight: 600;
      }

      .lower_v_2 {
          font-family: 'Poppins';
          font-size: 12px;
          font-weight: 400;
          height: 50px;
          overflow: scroll;
      }



      .lower_v_3 {
          width: 100%;
          padding: 10px 0;
          border-radius: 7.99px;
          background: #187C8B;
          color: white;
          border: none;
          display: flex;
          justify-content: center;
          cursor: pointer;
      }

  }


}
.AuctionSpaceButton{
  padding: 12px 24px;
  border-radius: 71px;
  color: white;
  border: none;
  font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: left;
background-color: #187C8B;
}

.image_section{
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
.image_section img{
  width: 35%;
}
.content_section{
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap:0.8rem;
}
.lufga_20px {
  font-family: 'Lufga', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: left;

}
.btn-Primary{
  background: #187c8b !important;
  color: #ffffff !important;
  }


  .image_section{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.image_section img{
    width: 35%;
}

.content_section{
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
}

.lufga_20px {
    font-family: 'Lufga', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;

}

.custom_gray{
    color: #97989F;
    }
    
    .theme_color{
        color: #187c8b;
        }