.ConfirmationText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;

}

.CitzenText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.Yesbutton {
    background-color: #187C8B;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    color: white;
}

.Nobutton {
    background-color: white;
    font-family: Lufga;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px 24px;
    border: none;
    border: 1px solid black;
    border-radius: 8px;
    color: black;
}


.PaymentMethodText{
    font-family: Lufga;
font-size: 20px;
font-weight: 400;
line-height: 48px;
letter-spacing: 0em;
text-align: center;
color: #187C8B;


}
.GoldText{
    font-family: Lufga;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: black;

}
.CreaditCradText{
    font-family: Lufga;
font-size: 18px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: #202224;

}
.CraeditCardDiv{
    display: flex;
    flex-direction: column;
}
.accptCrads{
    font-family: Lufga;
font-size: 16px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #202224;
}

.CancelPayButton{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: #187C8B;
padding: 21px 26px;
border-radius: 12px;
border: none;
background-color: transparent;

}
.InputCustom1{
    width: 100%;
   
}
.InputCustom{
    padding: 12px
}
.PayButton{
    font-family: Lufga;
font-size: 18px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
background-color: #187C8B;
width: 194px;
border-radius: 12px;
border: none;
color: white;

}
.EyeView {
    position: relative;
}

.View {
    position: absolute;
    top: 33%;
    font-size: 20px;
    right: 10%;


}
.AuctionSpaceButton{
    padding: 12px 24px;
    border-radius: 71px;
    color: white;
    border: none;
    font-family: Lufga;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.30000001192092896px;
text-align: left;
background-color: #187C8B;
}

