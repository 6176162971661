.lufga_12px{
    font-family: 'Lufga', sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_14px{
    font-family: 'Lufga', sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_16px{
    font-family: 'Lufga', sans-serif;
    font-size:1rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_18px{
    font-family: 'Lufga', sans-serif;
    font-size:1.125rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_20px{
    font-family: 'Lufga', sans-serif;
    font-size:1.25rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_22px{
    font-family: 'Lufga', sans-serif;
    font-size:1.375rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_24px{
    font-family: 'Lufga', sans-serif;
    font-size:1.5rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_26px{
    font-family: 'Lufga', sans-serif;
    font-size:1.625rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_28px{
    font-family: 'Lufga', sans-serif;
    font-size:1.75rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}
.lufga_noaucton{
    font-family: 'Lufga', sans-serif;
    font-size:1.75rem;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_30px{
    font-family: 'Lufga', sans-serif;
    font-size:1.875rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_32px{
    font-family: 'Lufga', sans-serif;
    font-size:2rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_34px{
    font-family: 'Lufga', sans-serif;
    font-size:2.125rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}


.lufga_36px{
    font-family: 'Lufga', sans-serif;
    font-size:2.25rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_38px{
    font-family: 'Lufga', sans-serif;
    font-size:2.375rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_40px{
    font-family: 'Lufga', sans-serif;
    font-size:2.5rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_42px{
    font-family: 'Lufga', sans-serif;
    font-size:2.625rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.lufga_44px{
    font-family: 'Lufga', sans-serif;
    font-size:2.75rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
}

.fw_400{
    font-weight: 400 !important;
}

.fw_500{
    font-weight: 500 !important;
}

.fw_600{
    font-weight: 600 !important;
}

.fw_700{
    font-weight: 700 !important;
}



  
  .theme_color{
    color: #177D8B !important;
  }


  
  .volkhov_48px {
    font-family: Volkhov;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4.5rem;
    letter-spacing: -0.04em;
    text-align: left;
  
  }
  


  
  .navLink{
    text-decoration: none;
    border-bottom: none;

  }


  
  .quicksand_17px {
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  
.ls_1{
    letter-spacing: 0.1rem !important;
}
.ls_2{
    letter-spacing: 0.2rem !important;
}
.ls_3{
    letter-spacing: 0.3rem !important;
}
.ls_4{
    letter-spacing: 0.4rem !important;
}
.ls_5{
    letter-spacing: 0.5rem !important;
}
.ls_6{
    letter-spacing: 0.6rem !important;
}