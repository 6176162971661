.container {
    width: 100%;
    min-height: 85vh;
    margin-top: 3vh;
    background: linear-gradient(130.63deg, rgba(255, 255, 255, 1.0) 1.55%, rgba(174, 242, 239, 0.2) 97.02%);
    font-family: Lufga;
    height: auto;
}

.mainHeading {
    font-family: Lufga;
    font-weight: 600;
    text-align: center;
    font-size: 2em;
    padding-top: 5%;
    color: #000000;
}
.header {
    font-size: 30px;
    font-weight: 600;
    color: black;
    text-align: center;
}
.discription {
    font-size: 24px;
    color: #187c8B;
    text-align: center;
}

.bgImg{
    background: url(/public/images/image_2024_03_05T12_37_23_785Z.png);
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    /* border-left:3px solid rgba(24, 124, 139, 1);
    border-right: 3px solid rgba(24, 124, 139, 1); */
    border-top:3px solid rgba(24, 124, 139, 1);
    border-bottom: 3px solid rgba(24, 124, 139, 1); 
}
.heading{
    font-size: 30px;
    font-weight: 500;
    color: black;
    text-align: center;
    font-family: Lufga;
}
.heading1{
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-align: center;
    font-family: Lufga;
}