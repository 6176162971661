.popover_item {
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.012em;
    color: #535457;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
