.bannersize {
    background-color: #ffffff;
    border-radius: 8px;
    height: 220px;
    display: flex;
    justify-content: space-between;
}

.ProfileImg1{
    width: 171px;
    height: 171px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
}

.inputIcon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    background: #00000063;
    
    }


    
.BnnerFirstText {
    font-family: Lufga;
    font-size: 25px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #187C8B;
}

.BnnersecondtText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #000000;
}

.ProfileImg{
    width: 235px;
    height: 235px;
    border-radius: 100%;
}

.verfiedText {
    font-family: Lufga;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
}