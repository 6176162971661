.p-dialog-content {
    border-radius: 6px;
    padding: 10px 40px;

}

.HeaderDialog {
    width: 100%;
    background-color: #F5F6FA;
    padding: 20px;

}

.PrifileHeding {
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    padding-left: 20px;
}

.VerfiedButton1 {
    font-family: Lufga;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.22500000894069672px;
    text-align: left;
    background-color: #009252;
    color: white;
    padding: 8px 24px;
    border-radius: 71px;
    border: none;
}

.Textname {
    font-family: Lufga;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: black;

}

.ProfileText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #6A6A6A;
    display: flex;
    justify-content: center;
}

.ViewResumeButton {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #187C8B;
    color: white;

}

.SaveProfileButton {

    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    border: none;
    border-radius: 71px;
    padding: 12px 24px;
    background-color: #F5F6FA;
    color: #202224;
}

.LabelHeading {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;
}

.HistoryText {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #187C8B;

}

.MiddleSubHeading {
    font-family: Lufga;
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: black;

}

.SubLAbelHedaing {
    font-family: Lufga;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;

}

.child1::placeholder {
    background-color: red;
}

.InputPlaceholder::placeholder {
    color: red;
    /* Set the color of the placeholder text */
    opacity: 1;
    /* Ensure the placeholder text is fully visible */
}

.LoctionText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #202224;
}

.card_wrapper a {
    text-decoration: none;
    color: unset;
}

.card {
    border: 1px solid #E8E8EA;
    border-radius: 22px !important;
}

.card:hover {
    background: unset !important;
    color: unset;
}

.InputCustom {
    background-color: #E8E8EA;
}

.view_button {
    border-radius: 71px;
    background: #187C8B;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10px 20px;
    border: none;
}

.verifySubtext {
    font-family: Lufga;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    color: #187C8B;
}

.MainHeading {
    font-family: Lufga;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    color: black;
    text-align: center;
}

.pay_button {
    border-radius: 12px;
    background: #187C8B;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10px 20px;
    border: none;
}


.no_style_button {
    border: none;
    background: transparent;
}


@media screen and (max-width: 600px) {

    .verifySubtext {
        font-family: Lufga;
        font-size: 1.1em;
        font-weight: 400;
        text-align: center;
        color: #187C8B;
    }
    
    .MainHeading {
        font-family: Lufga;
        font-size: 1.5em;
        font-weight: 600;
        text-align: center;
        color: black;
        text-align: center;
    }

    .LabelHeading {
        font-family: Lufga;
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 0.012em;
        text-align: left;
        color: #101828;
    }
}