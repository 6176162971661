.social_pages_background {
  background-color: white;
  /* margin: 2rem; */
  border-radius: 15px;
  height: 100%;
  width: 100%;
  padding: 30px 20px 20px 20px;
}

.social_pages_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
}

.social_handel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.3rem;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.input_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 50.5vw; */
  width: 80%;
  padding: 5px;
  background-color: #f5f6fa;
  border: 0.1px solid #e9e9f0;
  border-radius: 5px;
}

.main_input {
  /* background-color: red; */
}

.addBtn {
  background: #187c8b;
  border: none;
  outline: none;
  font-size: 1.2rem;
  padding: 0.4rem 1.5rem;
  border-radius: 8px;
  color: white;
  font-family: Lufga;
}
